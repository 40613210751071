import { Container, Typography } from '@material-ui/core';
import { RootStyle } from '../Course/CoursePreview/CoursePreview';

export function BaoMat() {
  return (
    <RootStyle title="The Forum Education | Chính sách bảo mật thông tin">
      <Container sx={{ lineHeight: 2 }}>
        <Typography variant="h3" gutterBottom textAlign="center">
          Chính Sách Bảo Vệ Thông Tin Cá Nhân Người Tiêu Dùng
        </Typography>
        <p>
          <strong>1. Cam kết của&nbsp;chúng tôi về bảo mật thông tin</strong>
        </p>
        <ul>
          <li>
            Chúng tôi tôn trọng quyền riêng tư của Quý khách cũng như các lựa chọn của Quý khách.
          </li>
          <li>
            Chúng tôi bảo đảm rằng sự bảo mật và an toàn luôn đi đôi trong mọi công việc chúng tôi
            thực hiện.
          </li>
          <li>
            Chúng tôi không gửi cho Quý khách các thư từ tiếp thị, trừ trường hợp Quý khách yêu cầu
            chúng tôi gửi các thông tin đó. Tại bất kỳ thời điểm nào, Quý khách đều có thể thay đổi
            ý định của mình về việc này bằng cách liên hệ trực tiếp với chúng tôi qua email
            hr@theforumcenter.vn
          </li>
          <li>Chúng tôi không bao giờ chào mời hoặc bán các thông tin của Quý khách.</li>
          <li>
            Chúng tôi cam kết giữ an toàn và bảo mật cho các thông tin của Quý khách, bao gồm cả cam
            kết chỉ giao dịch với các đối tác đáng tin cậy.
          </li>
          <li>
            Chúng tôi cam kết sẽ công khai và minh bạch về cách thức sử dụng các thông tin của Quý
            khách.
          </li>
          <li>
            Chúng tôi không sử dụng các thông tin của Quý khách theo các phương thức chưa thông báo
            cho Quý khách.
          </li>
          <li>
            Chúng tôi tôn trọng các quyền của Quý khách và luôn cố gắng đáp ứng các yêu cầu của Quý
            khách trong phạm vi nhiều nhất có thể, phù hợp với các trách nhiệm của chúng tôi về pháp
            lý và nghiệp vụ hoạt động.
          </li>
        </ul>
        <p>
          <strong>2. Mục đích và phạm vi thu thập</strong>
        </p>
        <p>
          Việc thu thập dữ liệu chủ yếu trên website (nếu có) của công ty bao gồm: họ tên, điện
          thoại Quý khách trong mục liên hệ. Đây là các thông tin mà công ty cần Quý khách cung cấp
          bắt buộc khi gửi thông tin nhờ tư vấn về sản phẩm, dịch vụ và để công ty liên hệ xác nhận
          lại với Quý khách trên website nhằm đảm bảo quyền lợi cho cho người tiêu dùng.
        </p>
        <p>
          Quý khách sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ dưới
          thông tin mà mình cung cấp và hộp thư điện tử của mình. Ngoài ra, Quý khách có trách nhiệm
          thông báo kịp thời cho webiste của công ty về những hành vi sử dụng trái phép, lạm dụng,
          vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện pháp giải quyết
          phù hợp.
        </p>
        <p>
          <strong>3. Phạm vi sử dụng thông tin</strong>
        </p>
        <p>Công ty sử dụng thông tin Quý khách cung cấp để:</p>
        <ul>
          <li>
            Cung cấp thông tin về sản phẩm, dịch vụ đến Quý khách nếu có yêu cầu từ Quý khách;
          </li>
          <li>
            Gọi điện tiếp thị, khuyến mại về sản phẩm, dịch vụ do chúng tôi bán khi nhận được yêu
            cầu từ Quý khách;
          </li>
          <li>Gửi các thông báo về các hoạt động của website công ty;</li>
          <li>Liên lạc và giải quyết với Quý khách trong những trường hợp đặc biệt;</li>
          <li>
            Không sử dụng thông tin cá nhân của Quý khách ngoài mục đích xác nhận và liên hệ có liên
            quan đến giao dịch tại công ty;
          </li>
        </ul>
        <p>
          –&nbsp;&nbsp; Khi có yêu cầu của cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án, cơ quan
          công an điều tra liên quan đến hành vi vi phạm pháp luật nào đó của Quý khách.
        </p>
        <p>
          <strong>4. Thời gian lưu trữ thông tin</strong>
        </p>
        <p>
          Dữ liệu cá nhân của Quý khách sẽ được lưu trữ cho đến khi có yêu cầu ban quản trị hủy bỏ.
          Còn lại trong mọi trường hợp thông tin cá nhân của Quý khách sẽ được bảo mật trên máy chủ
          của công ty.
        </p>
        <p>
          <strong>5. Những người hoặc tổ chức có thể tiếp cận thông tin</strong>
        </p>
        <p>
          - The Forum và các bộ phận liên quan đến việc hỗ trợ và thực hiện hợp đồng với khách hàng.
        </p>
        <p>
          - Trong trường hợp có yêu cầu của pháp luật: Công ty có trách nhiệm hợp tác cung cấp thông
          tin cá nhân khách hàng khi có yêu cầu từ cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án,
          cơ quan công an điều tra liên quan đến hành vi vi phạm pháp luật nào đó của khách hàng.
          Ngoài ra, không ai có quyền xâm phạm vào thông tin cá nhân của khách hàng.
        </p>
        <p>
          <strong>6. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân</strong>
        </p>
        <p>CÔNG TY TNHH THE FORUM EDUCATION VIETNAM</p>
        <p>
          Giấy chứng nhận đăng ký kinh doanh số:&nbsp;3502386218 do Sở Kế hoạch và Đầu tư tỉnh Bà
          Rịa – Vũng Tàu cấp ngày 18/01/2019
        </p>
        <p>Người đại diện theo pháp luật: Nguyễn Hoàng Huy</p>
        <p>
          Địa chỉ: 27G1 Nguyễn Oanh, Phường 7, Thành phố Vũng Tàu, Tỉnh Bà Rịa – Vũng Tàu, Việt Nam
        </p>
        <p>Số điện thoại: 1900886640</p>
        <p>Email: education@theforumcenter.vn</p>
        <p>
          <strong>
            7. Phương tiện và công cụ để Quý khách tiếp cận và chỉnh sửa dữ liệu cá nhân của mình.
          </strong>
        </p>
        <p>
          Quý khách có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông tin cá nhân của
          mình bằng cách liên hệ với ban quản trị website của công ty thực hiện việc này.
        </p>
        <p>
          Quý khách có quyền gửi khiếu nại về việc bị lộ thông tin cá nhân đến Ban quản trị của
          website của công ty. Khi tiếp nhận những phản hồi này, công ty sẽ xác nhận lại thông tin,
          trường hợp đúng như phản ánh của Quý khách tùy theo mức độ, công ty sẽ có những biện pháp
          xử lý kịp thời.
        </p>
      </Container>
    </RootStyle>
  );
}
