import {
  Button,
  CircularProgress,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@material-ui/core';
import useMediaRecorder from '@wmik/use-media-recorder';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadFile } from 'src/apis/firebase.api';
import { notifyTeamAssignment } from 'src/apis/lesson-assignment.api';
import { CreateLessonAssignment } from 'src/store/lesson-assignment/lesson-assignment.action';
import { formatUser } from 'src/utils/formatUser';

const MAX_ASSIGNMENT_SUBMIT = 5;
const MAX_FILE_SIZE = 10000000;

export const SubmitWritingAssignment = ({
  previousAssignment,
  loading,
  lessonId,
  lessonName,
  userState
}) => {
  const [assignment, setAssignment] = useState({ type: null });
  const [message, setMessage] = useState();
  const [uploading, setUploading] = useState(false);
  const [currentFile, setCurrentFile] = useState();

  const dispatch = useDispatch();

  const handleFileChange = (files) => {
    if (
      previousAssignment.filter((document) => document.type === 'writing').length ===
      MAX_ASSIGNMENT_SUBMIT
    ) {
      setMessage('Bạn đã đạt giới hạn nộp bài');
      return;
    }
    setMessage('Nhấn nộp bài để lưu bài mới nhé!');
    if (files.length > 0) {
      // setUploading(true);
      const file = files[0];
      if (file.size > MAX_FILE_SIZE) {
        setMessage('Kích thước bài nộp < 15mb. Vui lòng kiểm tra lại!');
        return;
      }
      setCurrentFile(file);

      setAssignment({
        name: file.name.substr(0, file.name.lastIndexOf('.')),
        type: 'writing',
        url: null,
        lessonId: lessonId
      });
      //   setUploading(false);
      // });
    } else {
      window.alert('Chưa chọn file');
    }
  };

  const handleSubmitAssigment = (path) => {
    setUploading(true);
    uploadFile(currentFile, path).then(async (url) => {
      const filename = currentFile.name.substr(0, currentFile.name.lastIndexOf('.'));
      dispatch(
        CreateLessonAssignment({
          name: filename,
          type: 'writing',
          url: url,
          lessonId: lessonId
        })
      );

      const request = {
        course: lessonName,
        type: 'Writing',
        filename: currentFile.name,
        url: url,
        ...formatUser(userState.data.current)
      };
      // console.log(request);
      await notifyTeamAssignment(request);
      setUploading(false);
      setAssignment({ type: null });
    });
  };

  return (
    <Stack flexDirection="column" sx={{ width: { md: '500px', xs: '80vw' }, alignItems: 'center' }}>
      <Typography variant="body" gutterBottom>
        Chọn bài nộp Writting
      </Typography>

      {message && (
        <Typography variant="body2" color="red" textAlign="center">
          {message}
        </Typography>
      )}

      <Button
        variant="outlined"
        component="label"
        sx={{ width: '50%', margin: '0.5em auto', mb: 1 }}
        disabled={uploading}
      >
        {uploading ? 'Đang tải...' : 'Chọn file'}
        <input
          hidden
          type="file"
          accept=".pdf,.docx,application/msword"
          id="upload-assignment"
          name="upload-assignment"
          onChange={(e) => handleFileChange(e.target.files)}
        />
      </Button>
      <Button
        sx={{ width: '50%', margin: '0.5em auto' }}
        variant="contained"
        component="label"
        onClick={() =>
          handleSubmitAssigment(`assignments/${localStorage.getItem('last-id-selected')}/writing`)
        }
        disabled={!assignment || uploading || !assignment.type}
      >
        Nộp bài
      </Button>

      <Divider sx={{ my: 2, width: '100%' }} />

      <Typography variant="body2">{`Các bài đã nộp (${
        previousAssignment.filter((document) => document.type === 'writing').length
      } bài)`}</Typography>
      {!loading ? (
        <List component="nav" style={{ marginBottom: 5, width: '100%' }}>
          {[...previousAssignment, assignment]
            .filter((document) => document.type === 'writing')
            .map((document, index) => (
              <ListItem
                button
                component="a"
                href={document.url}
                target="__blank"
                key={`documents_file_${index}`}
              >
                <ListItemIcon>
                  <Icon>description</Icon>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  primary={`${index + 1}. ${document.name}`}
                  prefix="..."
                />
              </ListItem>
            ))}
        </List>
      ) : (
        <CircularProgress color="primary" />
      )}
    </Stack>
  );
};

function Player({ srcBlob, audio, url }) {
  if (!srcBlob && !url) {
    return null;
  }

  if (audio) {
    if (url) return <audio src={url} controls autoPlay />;
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }

  // return <video src={URL.createObjectURL(srcBlob)} width={520} height={480} controls />;
}

export const SubmitSpeakingAssignment = ({ previousAssignment, loading, lessonId }) => {
  const [uploading, setUploading] = useState(false);
  const [url, setUrl] = useState();
  const [message, setMessage] = useState();

  const dispatch = useDispatch();

  const { error, status, mediaBlob, stopRecording, getMediaStream, startRecording } =
    useMediaRecorder({
      recordScreen: false,
      blobOptions: { type: 'video/webm' },
      mediaStreamConstraints: { audio: true }
    });

  useEffect(() => {
    if (error) window.alert(`Vui lòng kiểm tra lại Quyền truy cập Microphone, ${error}`);
  }, [error]);

  const handleRecording = (e) => {
    setMessage('Nhấn nộp bài để lưu bài mới nhé!');
    if (
      previousAssignment.filter((document) => document.type === 'speaking').length ===
      MAX_ASSIGNMENT_SUBMIT
    ) {
      setMessage('Bạn đã đạt giới hạn nộp bài');
      return;
    }
    setUrl();
    if (status === 'recording') stopRecording(e);
    else startRecording(e);
  };

  const handleSubmitAssigment = () => {
    if (mediaBlob.size > MAX_FILE_SIZE) {
      setMessage('Kích thước bài thi < 15mb. Vui lòng kiểm tra lại!');
      return;
    }

    if (
      previousAssignment.filter((document) => document.type === 'speaking').length ===
      MAX_ASSIGNMENT_SUBMIT
    ) {
      setMessage('Bạn đã đạt giới hạn nộp bài');
      return;
    }
    setUploading(true);
    uploadFile(
      mediaBlob,
      `assignments/${localStorage.getItem('last-id-selected')}/speaking`,
      `speaking-${new Date()}`
    ).then((url) => {
      // console.log({
      //   Filename: 'Bài nộp 1',
      //   URL: url
      // });
      dispatch(
        CreateLessonAssignment({
          name: `Recording-${new Date().toLocaleString()}`,
          type: 'speaking',
          url: url,
          lessonId: lessonId
        })
      );
      setUploading(false);
    });
  };

  return (
    <Stack sx={{ width: { md: '500px', xs: '80vw' }, alignItems: 'center' }}>
      <Typography variant="body" gutterBottom textAlign="center">
        {status === 'recording' ? 'Đang ghi...' : 'Nhấn ghi âm để bắt đầu bài Speaking'}
        {/* {error ? `${status} ${error.message}` : status} */}
      </Typography>

      {message && (
        <Typography variant="body2" color="red" textAlign="center">
          {message}
        </Typography>
      )}

      {/* <button type="button" onClick={getMediaStream} disabled={status === 'ready'}>
          Share screen
        </button> */}
      {!message && mediaBlob && status !== 'recording' && (
        <Typography variant="body2" color="red" textAlign="center">
          Nhấn nộp bài để lưu bài mới nhé!
        </Typography>
      )}

      {status !== 'recording' && <Player srcBlob={mediaBlob} audio url={url} />}

      <Button
        disabled={uploading}
        variant={status === 'recording' ? 'contained' : 'outlined'}
        onClick={handleRecording}
        sx={{ width: '50%', margin: '0.5em auto', mb: 1 }}
      >
        {status !== 'recording' ? 'Ghi âm' : 'Dừng'}
      </Button>

      <Button
        sx={{ width: '50%', margin: '0.5em auto' }}
        variant="contained"
        component="label"
        onClick={handleSubmitAssigment}
        disabled={!mediaBlob || uploading || !mediaBlob || status === 'recording'}
      >
        Nộp bài
      </Button>

      <Divider sx={{ my: 2, width: '100%' }} />

      <Typography variant="body2">{`Các bài đã nộp (${
        previousAssignment.filter((document) => document.type === 'speaking').length
      } bài)`}</Typography>

      {!loading ? (
        <List component="nav" style={{ marginBottom: 5, width: '100%' }}>
          {previousAssignment

            .filter((document) => document.type === 'speaking')
            .map((document, index) => (
              <ListItem
                button
                onClick={() => setUrl(document.url)}
                target="__blank"
                key={`documents_file_${index}`}
              >
                <ListItemIcon>
                  <Icon>mic</Icon>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  primary={`${index + 1}. ${document.name}`}
                  prefix="..."
                />
              </ListItem>
            ))}
        </List>
      ) : (
        <CircularProgress color="primary" />
      )}
    </Stack>
  );
};
