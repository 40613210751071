import {
  SEND_COD_FORM,
  SEND_COD_FORM_FAILED,
  SEND_COD_FORM_SUCCESSFULLY,
  SET_CONTENT_DIALOG_COD,
  SET_CONTENT_DIALOG_SUPPORT,
  TOGGLE_DIALOG_COD,
  TOGGLE_DIALOG_SUPPORT
} from './dialog.action';

const initialDialogState = {
  data: null,
  supportDialog: {
    open: false,
    content: { name: '', phone: '', note: '' }
  },
  codDialog: {
    open: false,
    content: { name: '', phone: '', course: '', note: '' }
  },
  loading: false,
  errorMessage: ''
};

export function dialogReducer(state = initialDialogState, action) {
  switch (action.type) {
    case TOGGLE_DIALOG_SUPPORT:
      return {
        ...state,
        supportDialog: { ...state.supportDialog, open: !state.supportDialog.open }
      };
    case SET_CONTENT_DIALOG_SUPPORT:
      return {
        ...state,
        supportDialog: { ...state.supportDialog, content: action.payload }
      };
    case TOGGLE_DIALOG_COD:
      return {
        ...state,
        codDialog: { ...state.codDialog, open: !state.codDialog.open }
      };
    case SET_CONTENT_DIALOG_COD:
      return {
        ...state,
        codDialog: { ...state.codDialog, content: action.payload }
      };

    case SEND_COD_FORM:
      return {
        ...state,
        loading: true
      };
    case SEND_COD_FORM_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case SEND_COD_FORM_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
}
