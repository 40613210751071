import { signOut } from '@firebase/auth';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, of, takeUntil } from 'rxjs';
import { ACCESS_TOKEN } from 'src/constants/constant';
import { Navigate } from 'src/store/navigation/navigation.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { signInByToken } from '../../../apis/auth.api';
import { loginWithGoogle, loginWithPassword } from '../../../apis/firebase.api';
import { LOGIN, LoginFailed, LoginSuccessful, LOGIN_FAILED, LOGIN_GOOLE } from './signin.action';

export const signInEpic = (_action$) =>
  _action$.pipe(
    ofType(LOGIN),
    mergeMap((action) => {
      const { email, password, remembered } = action.payload;
      let userInfo;
      return loginWithPassword(email, password, remembered).pipe(
        mergeMap((res) => {
          // UserCredential
          const resParse = res.user.toJSON();
          userInfo = {
            displayName: resParse.displayName,
            email: resParse.email,
            photoURL: resParse.photoURL
          };
          return signInByToken({
            token: resParse.stsTokenManager.accessToken
          });
        }),
        map((signInRes) => LoginSuccessful({ token: signInRes.data, userInfo })),
        takeUntil(_action$.pipe(ofType(LOGIN_FAILED))),
        catchError((err) => {
          console.log('err', err);
          return of(LoginFailed(err));
        })
      );
    })
  );

export const signInGoogleEpic = (_action$) =>
  _action$.pipe(
    ofType(LOGIN_GOOLE),
    mergeMap((action) => {
      let userInfo;
      return loginWithGoogle().pipe(
        mergeMap((res) => {
          // UserCredential
          const credential = res.user.toJSON();
          userInfo = {
            displayName: credential.displayName,
            email: credential.email,
            photoURL: credential.photoURL
          };
          const names = credential.displayName.split(' ');
          return signInByToken({
            token: credential.stsTokenManager.accessToken,
            firstName: names[0],
            lastName: names[names.length - 1]
          });
        }),
        map((signInRes) =>
          LoginSuccessful({
            token: signInRes.data,
            userInfo
          })
        ),
        takeUntil(_action$.pipe(ofType(LOGIN_FAILED))),
        catchError((err) => {
          console.log('err', err);
          return of(LoginFailed('Vui lòng cập nhật số điện thoại'));
        })
      );
    })
  );
