import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Link,
  Paper,
  Rating,
  Typography
} from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link as RouterLink } from 'react-router-dom';
import Label from 'src/components/Label';
import { DISCOUNT } from 'src/constants/constant';
// utils
import { formatCurrency } from 'src/utils/formatcurrency';
import { UrlConfigs } from 'src/utils/UrlConfig';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});

export const TitleStyle = styled(Typography)({
  color: 'black',
  height: 20,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});

export const SubTitleStyle = styled(Typography)({
  display: '-webkit-box',
  color: 'brown',
  WebkitBoxOrient: 'vertical'
});

export const IntroStyle = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical'
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

export const PaperStyle = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  border: '1px solid lightgrey',
  minHeight: '200px',
  maxHeight: '300px',
  overflow: 'auto',
  width: '70vw',
  maxWidth: '1000px',
  boxShadow: theme.customShadows.z8
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  color: theme.palette.text.disabled
}));

export const CoverImgStyle = styled('img')({
  width: '100%',
  height: '50%',
  position: 'relative'
});

// ----------------------------------------------------------------------

export const handlePopoverOpen = (event) => {
  event.currentTarget.style.transform = 'scale(1.05)';
};

export const handlePopoverClose = (event) => {
  event.currentTarget.style.transform = 'none';
};

TopCourseCard.propTypes = {
  lessons: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default function TopCourseCard({ lessons, index }) {
  const [anchor, setAnchor] = useState(null);

  const { thumbnail, name, price, introduction, subtitle, level, _id, seoTitle } = lessons;
  // 2 khoá học đầu bự hơn
  // const latestPostLarge = [0, 1].indexOf(index) !== -1;
  // const latestPost = index === 2 || index === 3;

  // const POST_INFO = [
  //   { number: comment, icon: messageCircleFill },
  //   { number: view, icon: eyeFill },
  //   { number: share, icon: shareFill }
  // ];
  const [rateValue, setRateValue] = useState(4.5);

  return (
    <Grid item xs={12} lg={4} marginX={{ md: 5, sm: 0 }}>
      <Card
        sx={{
          position: 'relative',
          transition: 'all .5s',
          background: '#fffafa',
          height: 'fit-content'
        }}
        title={introduction}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <CardActionArea
          LinkComponent={RouterLink}
          to={`${UrlConfigs.LESSON_DETAIL}/${seoTitle}`}
          sx={{ height: '100%' }}
        >
          <Label
            variant="filled"
            color="error"
            sx={{
              zIndex: 9,
              top: 16,
              right: DISCOUNT > 0 ? 80 : 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            Hot
          </Label>
          {DISCOUNT > 0 && (
            <Label
              variant="filled"
              color="warning"
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase'
              }}
            >
              -{DISCOUNT}%
            </Label>
          )}
          {/* <CardMediaStyle
            sx={{
              ...{
                '&:after': {
                  top: 0,
                  content: "''",
                  width: '70%',
                  height: '70%',
                  position: 'absolute'
                }
              },
              ...{
                pt: {
                  xs: 'calc(100% * 2 / 3)',
                  lg: 'calc(100% * 3 / 4)'
                }
              }
            }}
          > */}
          {/* <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute'
            }}
          /> */}

          {/* <AvatarStyle alt={author.name} src={author.avatarUrl} /> */}

          <CoverImgStyle alt={name} src={thumbnail} />

          {/* <Popper
              open={Boolean(anchor)}
              anchorEl={anchor}
              placement="right-start"
              transition
              disablePortal={false}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={{ enter: 350 }}>
                  <PaperStyle>
                    <Typography sx={{ pt: 3 }} variant="h4" textAlign="center" color="red">
                      {name}
                    </Typography>
                    <Typography sx={{ p: 1.5, pt: 3 }}>
                      {index === 0
                        ? 'Khóa học đem lại hiểu biết đầy đủ về tất cả các kỹ năng của kì thi IELTS và những kỹ thuật làm bài cam kết đem lại hiệu quả. Người học sẽ nắm được'
                        : 'Khóa học phù hợp với người đã có kinh nghiệm về IELTS, giúp nghiên cứu sâu cấu trúc đề, cũng như đưa ra các giải pháp phân tích đề bài, giúp nâng cao band điểm hiệu quả. Người học sẽ có được'}
                    </Typography>
                    <List sx={{ marginBottom: '50px' }}>
                      <ListItem>
                        <ListItemIcon>
                          <BeenhereIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            index === 0
                              ? 'Cách chinh phục Reading bằng kỹ năng độc quyền của The Forum'
                              : 'Hướng dẫn viết Writing cụ thể, học thuật, bám sát yêu cầu đề'
                          }
                          // secondary="hihihi"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <BeenhereIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            index === 0
                              ? 'Khả năng nghe và dự đoán những đoạn hội thoại khó '
                              : 'Sử dụng từ vựng phức tạp, collocation, các idioms phù hợp với kì thi IELTS'
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <BeenhereIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            index === 0
                              ? 'Kỹ thuật viết bài chuẩn xác, đạt yêu cầu '
                              : 'Kỹ thuật Reading độc quyền của The Forum'
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <BeenhereIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            index === 0
                              ? 'Khả năng phát âm từ cơ bản tới nâng cao'
                              : 'Khả năng nghe trọn vẹn 4 phần thi Listening mà không bị mất nhịp hoặc sự tập trung.'
                          }
                        />
                      </ListItem>
                    </List>
                    <Link
                      underline="none"
                      href={`${UrlConfigs.LESSON_DETAIL}/${_id}`}
                      sx={{
                        alignSelf: 'end',
                        pt: 2,
                        position: 'absolute',
                        bottom: '30px',
                        width: '88%'
                      }}
                    >
                      <Button fullWidth target="_blank" variant="outlined">
                        Nhận
                      </Button>
                    </Link>
                  </PaperStyle>
                </Fade>
              )}
            </Popper> */}
          {/* </CardMediaStyle> */}

          <CardContent>
            <InfoStyle>
              <div>
                <TitleStyle
                  variant="h4"
                  sx={{
                    ...{ height: 'fit-content' }
                  }}
                >
                  {name}
                </TitleStyle>
                <SubTitleStyle>{ReactHtmlParser(subtitle)}</SubTitleStyle>
                <Box display="flex">
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ color: 'red', display: 'block', mr: 1 }}
                  >
                    {formatCurrency(price * (1 - DISCOUNT / 100))}
                  </Typography>
                  {DISCOUNT > 0 && (
                    <Typography
                      variant="body"
                      gutterBottom
                      sx={{ textDecoration: 'line-through', alignSelf: 'center' }}
                    >
                      {formatCurrency(price)}
                    </Typography>
                  )}
                </Box>
                <Box component="fieldset" borderColor="transparent">
                  <Rating
                    name="simple-controlled"
                    value={rateValue}
                    onChange={(event, newValue) => {
                      setRateValue(newValue);
                    }}
                    readOnly
                    precision={0.5}
                  />
                </Box>
              </div>
              <IntroStyle variant="body2" mb="4em">
                {introduction}
              </IntroStyle>
            </InfoStyle>
            <Link
              component="div"
              underline="none"
              sx={{ width: 'calc(100% - 3rem)', position: 'absolute', bottom: '20px' }}
            >
              <Button
                fullWidth
                sx={{ height: '30%', typography: 'h5' }}
                target="_blank"
                variant="outlined"
              >
                Nhận
              </Button>
            </Link>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
