import { defer } from 'rxjs';
import http from '../network/http';

/**
 * @param lessonId
 * @param folderId
 */
export function createLessonTopic(lessonId, folderId) {
  return defer(() =>
    http.post(`/v1/lesson-topic/${lessonId}/playlist-or-folder`, {
      folderId: folderId
    })
  );
}

export function createLessonTopicYoutube(lessonId, playlistId) {
  return defer(() =>
    http.post(`/v1/lesson-topic/${lessonId}/playlist-or-folder`, {
      playlistId: playlistId
    })
  );
}

export function getAllTopicsByLessonId(lessonId) {
  return defer(() => http.get(`/v1/lesson-topic/lesson/${lessonId}`));
}

export function publicVideo(id, videoId) {
  return defer(() => http.put(`/v1/lesson-topic/${id}/video`, { videoId }));
}
