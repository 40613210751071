import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import {
  createLessonTopicYoutube,
  createLessonTopic,
  getAllTopicsByLessonId,
  publicVideo
} from 'src/apis/lesson-topic.api';
import { showError, showSuccess } from 'src/utils/toast';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { CreateLessonSuccessfully } from '../lesson/lesson.action';
import {
  CreateLessonTopicFailed,
  CreateLessonTopicSuccessfully,
  CREATE_LESSON_TOPIC,
  GetAllLessonTopic,
  GetAllLessonTopicFailed,
  GetAllLessonTopicSuccessfully,
  GET_ALL_LESSON_TOPIC,
  PUBLIC_VIDEO
} from './lesson-topic.action';

export const createLessonTopicEpic = (_action$) =>
  _action$.pipe(
    ofType(CREATE_LESSON_TOPIC),
    mergeMap((action) =>
      createLessonTopicYoutube(action.payload.lessonId, action.payload.playlistId).pipe(
        map((res) => {
          showSuccess('Cập nhật Videos thành công');
          return CreateLessonSuccessfully(res.data);
        }),
        map(() => CreateLessonTopicSuccessfully()),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau');
          return of(CreateLessonTopicFailed('Failed to create lesson data'));
        })
      )
    )
  );

export const getAllTopicsByLessonIdEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_ALL_LESSON_TOPIC),
    switchMap((action) =>
      getAllTopicsByLessonId(action.payload.lessonId).pipe(
        map((res) => {
          action.payload.setTopics(res.data);
          return GetAllLessonTopicSuccessfully();
        }),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại');
          return of(GetAllLessonTopicFailed('Failed to Get lesson topic data'));
        })
      )
    )
  );

export const publicVideoEpic = (_action$) =>
  _action$.pipe(
    ofType(PUBLIC_VIDEO),
    switchMap((action) =>
      publicVideo(action.payload.topicId, action.payload.videoId).pipe(
        map((res) => {
          action.payload.setTopics(res.data);
          return GetAllLessonTopic(action.payload.lessonId, action.payload.setTopics);
        }),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại');
          return of(GetAllLessonTopicFailed('Failed to public video'));
        })
      )
    )
  );
