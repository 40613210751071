import { Divider, Stack, TextField, Typography, Button } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { SignOut } from 'src/store/auth/signout/signout.action';
import { UpdateCurrentUser } from 'src/store/user/user.action';
import * as Yup from 'yup';
import { phoneRegExp } from '../authentication/register/RegisterForm';

const GeneralInfo = ({ user }) => {
  const dispatch = useDispatch();
  const AccountInfoSchema = Yup.object().shape({
    email: Yup.string().email('Sai định dạng email').required('Vui lòng nhập email'),
    firstName: Yup.string()
      .required('Vui lòng nhập họ và tên đệm')
      .matches(/^[^!@#^&*()./\\[\]<>%${},0123456789\-=+?:]+$/, 'Tên không hợp lệ'),
    lastName: Yup.string()
      .required('Vui lòng nhập tên')
      .matches(/^[^!@#^&*()./\\[\]<>%${},0123456789\-=+?:]+$/, 'Tên không hợp lệ'),
    phone: Yup.string().matches(phoneRegExp, 'Số điện thoại không hợp lệ')
  });

  const formik = useFormik({
    initialValues: {
      email: user && user.email ? user.email : '',
      firstName: user && user.firstName ? user.firstName : '',
      lastName: user && user.lastName ? user.lastName : '',
      phone: user && user.phone ? user.phone : ''
    },
    validationSchema: AccountInfoSchema,
    onSubmit: () => {
      dispatch(UpdateCurrentUser(values));
      setSubmitting(false);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Typography component="h3" style={{ fontSize: 18, fontWeight: 'bold' }}>
        Thông tin cơ bản
      </Typography>
      <Divider />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Địa chỉ email"
            placeholder="Nhập địa chỉ email"
            {...getFieldProps('email')}
            disabled
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <TextField
            fullWidth
            autoComplete="firstName"
            type="text"
            label="Họ tên đệm"
            placeholder="Nhập họ tên đệm"
            {...getFieldProps('firstName')}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <TextField
            disabled={values.lastName.indexOf(':') !== -1}
            value={values.lastName}
            fullWidth
            autoComplete="lastName"
            placeholder="Nhập tên"
            type="text"
            label="Tên"
            {...getFieldProps('lastName')}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <TextField
            fullWidth
            autoComplete="phone"
            type="phone"
            label="Số điện thoại"
            placeholder="Nhập số điện thoại"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
        </Stack>

        <LoadingButton
          style={{ float: 'right' }}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Cập nhật
        </LoadingButton>
        <Button
          size="large"
          style={{ float: 'left' }}
          variant="contained"
          color="inherit"
          onClick={() => dispatch(SignOut())}
        >
          Đăng xuất
        </Button>
      </Form>
    </FormikProvider>
  );
};

GeneralInfo.propTypes = {
  user: PropTypes.object
};
export default GeneralInfo;
