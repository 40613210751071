import { ofType } from 'redux-observable';
import { catchError, mergeMap, of, takeUntil, map } from 'rxjs';
import { changePassword, reAuthen } from 'src/apis/firebase.api';
import { showError, showSuccess } from 'src/utils/toast';
import {
  UpdatePasswordFailed,
  UpdatePasswordSuccessful,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILED
} from './updatepassword.action';

export const updatePasswordEpic = (_action$) =>
  _action$.pipe(
    ofType(UPDATE_PASSWORD),
    mergeMap((action) => {
      const { currentPassword, newPassword } = action.payload;
      return reAuthen(currentPassword).pipe(
        mergeMap((user) => {
          showSuccess('Đổi mật khẩu thành công');
          return changePassword(newPassword);
        }),
        map(() => UpdatePasswordSuccessful()),
        takeUntil(_action$.pipe(ofType(UPDATE_PASSWORD_FAILED))),
        catchError((err) => {
          showError('Đổi mật khẩu thất bại, vui lòng thử lại sau');
          console.log('err', err);
          return of(UpdatePasswordFailed(err));
        })
      );
    })
  );
