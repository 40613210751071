import {
  Container,
  InputLabel,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Autocomplete,
  Divider
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDocuemt } from 'src/apis/firebase.api';
import { showSuccess } from 'src/utils/toast';
import * as Yup from 'yup';

const CustomLabel = styled(InputLabel)(({ theme }) => ({
  color: 'inherit'
}));

const baseUrl =
  'https://script.google.com/macros/s/AKfycbylt234U5Yj4C4EvgxKmO-EmTno5nRGLInhorjACwV9n6otSI372Jz1V4aL4dHb-3u6/exec';

const CodDialogForm = ({ setSent, sent, sx }) => {
  const codDialog = useSelector((state) => state.dialogs.codDialog);
  const [provinces, setProvinces] = useState();
  const [districts, setDistricts] = useState();
  const [wards, setWards] = useState();

  useEffect(() => {
    axios.get('https://provinces.open-api.vn/api/p/').then((res) => {
      setProvinces(res.data);
    });
  }, []);

  const handleClickProvince = (provinceName, provinceCode) => {
    axios.get(`https://provinces.open-api.vn/api/p/${provinceCode}?depth=2`).then((res) => {
      setDistricts(res.data.districts);
      setFieldValue('addressDepth1', provinceName);
    });
  };

  const handleClickDistrict = (districtName, districtCode) => {
    axios.get(`https://provinces.open-api.vn/api/d/${districtCode}?depth=2`).then((res) => {
      setWards(res.data.wards);
      setFieldValue('addressDepth2', districtName);
    });
  };

  const handleClickWard = (wardName, wardCode) => {
    setFieldValue('addressDepth3', wardName);
  };

  const SupportSchema = Yup.object().shape({
    name: Yup.string().required('Vui lòng điền tên của bạn'),
    phone: Yup.string().required('Vui lòng điền số điện thoại'),
    addressDepth1: Yup.string().required('Không được bỏ trống'),
    addressDepth2: Yup.string().required('Không được bỏ trống'),
    addressDepth3: Yup.string().required('Không được bỏ trống'),
    addressDetail: Yup.string().required('Không được bỏ trống'),
    course: Yup.string().required('Không được bỏ trống'),
    note: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: codDialog.content.name || '',
      phone: codDialog.content.phone || '',
      course: codDialog.content.course || '',
      addressDepth1: '',
      addressDepth2: '',
      addressDepth3: '',
      addressDetail: '',
      note: codDialog.content.note || ''
    },
    validationSchema: SupportSchema,

    onSubmit: async () => {
      const dataToSend = {
        name: values.name,
        phone: values.phone,
        course: values.course,
        note: values.note,
        address: `${values.addressDetail} ${values.addressDepth3} ${values.addressDepth2} ${values.addressDepth1}`,
        agent: window.navigator.userAgent,
        ip: 'Viet Nam',
        date: new Date().toLocaleString('vi-VN')
      };
      await Promise.all([
        axios.post(baseUrl, dataToSend, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }),
        setDocuemt('cod', new Date().toLocaleString().replaceAll('/', '-'), dataToSend)
      ]);
      setSent(true);
      showSuccess(
        `Cảm ơn bạn ${codDialog.content.name} đã tin tưởng chúng tôi, chúng tôi sẽ liên hệ bạn ngay!`
      );
    }
  });

  useEffect(() => {
    if (codDialog.content) setFieldValue(codDialog.content);
  }, [codDialog]);

  const {
    errors,
    touched,
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue
  } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={sx}>
        <Typography variant="h3" pb={5} textAlign="center" sx={{ position: 'relative' }}>
          Thông tin đơn hàng
        </Typography>
        <Stack spacing={3}>
          <CustomLabel>Tên của bạn *</CustomLabel>
          <TextField
            fullWidth
            autoComplete="name"
            type="text"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white'
              }
            }}
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <CustomLabel>Số điện thoại của bạn *</CustomLabel>
          <TextField
            fullWidth
            autoComplete="phone"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white'
              }
            }}
            type="text"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
          <CustomLabel>Khóa học *</CustomLabel>
          <TextField
            disabled
            fullWidth
            autoComplete="course"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white'
              }
            }}
            type="text"
            {...getFieldProps('course')}
            error={Boolean(touched.course && errors.course)}
            helperText={touched.course && errors.course}
          />
          <CustomLabel>Địa chỉ *</CustomLabel>
          <Stack direction="row" spacing={3}>
            <Autocomplete
              id={`provice-${new Date().getTime()}`}
              options={
                provinces
                  ? provinces.map((province) => [province.name, province.code].join('#'))
                  : []
              }
              onChange={(event, newValue) => {
                const labels = newValue.split('#');
                handleClickProvince(labels[0], labels[1]);
              }}
              value={values.addressDepth1}
              disablePortal
              sx={{ width: '33%' }}
              renderOption={(props, option) => {
                const label = option.split('#');
                return (
                  <li {...props}>
                    <MenuItem>{label[0]}</MenuItem>
                    <Divider />
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(touched.addressDepth1 && errors.addressDepth1)}
                  helperText={touched.addressDepth1 && errors.addressDepth1}
                  placeholder="Tỉnh/Thành phố"
                />
              )}
              disableClearable
            />
            <Autocomplete
              id={`district-${new Date().getTime()}`}
              options={
                districts
                  ? districts.map((district) => [district.name, district.code].join('#'))
                  : []
              }
              onChange={(event, newValue) => {
                const labels = newValue.split('#');
                handleClickDistrict(labels[0], labels[1]);
              }}
              value={values.addressDepth2}
              disablePortal
              sx={{ width: '33%' }}
              renderOption={(props, option) => {
                const label = option.split('#');
                return (
                  <li {...props}>
                    <MenuItem>{label[0]}</MenuItem>
                    <Divider />
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(touched.addressDepth2 && errors.addressDepth2)}
                  helperText={touched.addressDepth2 && errors.addressDepth2}
                  placeholder="Quận/huyện"
                />
              )}
              disableClearable
            />
            <Autocomplete
              id={`ward-${new Date().getTime()}`}
              options={wards ? wards.map((ward) => [ward.name, ward.code].join('#')) : []}
              onChange={(event, newValue) => {
                const labels = newValue.split('#');
                handleClickWard(labels[0], labels[1]);
              }}
              value={values.addressDepth3}
              disablePortal
              sx={{ width: '33%' }}
              renderOption={(props, option) => {
                const label = option.split('#');
                return (
                  <li {...props}>
                    <MenuItem>{label[0]}</MenuItem>
                    <Divider />
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(touched.addressDepth3 && errors.addressDepth3)}
                  helperText={touched.addressDepth3 && errors.addressDepth3}
                  placeholder="Phường/xã"
                />
              )}
              disableClearable
            />
          </Stack>
          <CustomLabel>Số nhà *</CustomLabel>
          <TextField
            fullWidth
            multiline
            minRows={3}
            autoComplete="address"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white'
              }
            }}
            type="text"
            {...getFieldProps('addressDetail')}
            error={Boolean(touched.addressDetail && errors.addressDetail)}
            helperText={touched.addressDetail && errors.addressDetail}
          />
          <CustomLabel>Ghi chú thêm</CustomLabel>
          <TextField
            fullWidth
            multiline
            minRows={2}
            autoComplete="note"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white'
              }
            }}
            type="text"
            {...getFieldProps('note')}
            error={Boolean(touched.note && errors.note)}
            helperText={touched.note && errors.note}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ my: 2, color: 'white' }}
        >
          Hoàn thành
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};

export default CodDialogForm;
