export const CREATE_LESSON = 'CREATE_LESSON';
export const CREATE_LESSON_SUCCESSFULLY = 'CREATE_LESSON_SUCCESSFULLY';
export const CREATE_LESSON_FAILED = 'CREATE_LESSON_FAILED';

export const SELECT_LESSON = 'SELECT_LESSON';
export const GET_LESSON = 'GET_LESSON';
export const GET_LESSON_SEO = 'GET_LESSON_SEO';
export const GET_LESSON_BY_TAG = 'GET_LESSON_BY_TAG';
export const GET_LESSON_SEARCH = 'GET_LESSON_SEARCH';
export const GET_LESSON_ALL = 'GET_LESSON_ALL';
export const GET_LESSON_HOME = 'GET_LESSON_HOME';
export const GET_MY_LESSON = 'GET_MY_LESSON';
export const GET_MY_LESSON_SUCCESSFULLY = 'GET_MY_LESSON_SUCCESSFULLY';
export const GET_LESSON_ALL_SUCCESSFULLY = 'GET_LESSON_ALL_SUCCESSFULLY';
export const GET_LESSON_SEARCH_SUCCESSFULLY = 'GET_LESSON_SEARCH_SUCCESSFULLY';
export const GET_LESSON_SUCCESSFULLY = 'GET_LESSON_SUCCESSFULLY';
export const GET_LESSON_SEO_SUCCESSFULLY = 'GET_LESSON_SEO_SUCCESSFULLY';
export const GET_LESSON_BY_TAG_SUCCESSFULLY = 'GET_LESSON_BY_TAG_SUCCESSFULLY';
export const GET_LESSON_FAILED = 'GET_LESSON_FAILED';

export const UPDATE_LESSON = 'UPDATE_LESSON';
export const UPDATE_LESSON_SUCCESSFULLY = 'UPDATE_LESSON_SUCCESSFULLY';
export const UPDATE_LESSON_FAILED = 'UPDATE_LESSON_FAILED';

export const SEEN_LESSON = 'SEEN_LESSON';
export const SEEN_LESSON_SUCCESSFULLY = 'SEEN_LESSON_SUCCESSFULLY';
export const SEEN_LESSON_FAILED = 'SEEN_LESSON_FAILED';
export const GET_LESSON_DONE = 'GET_LESSON_DONE';
export const GET_LESSON_DONE_SUCCESFULLY = 'GET_LESSON_DONE_SUCCESFULLY';
export const GET_LESSON_DONE_FAILED = 'GET_LESSON_DONE_FAILED';

export const CLEAR_SELECTED_LESSON = 'CLEAR_SELECTED_LESSON';

export function CreateLesson(lesson) {
  return {
    type: CREATE_LESSON,
    payload: lesson
  };
}
export function CreateLessonSuccessfully(lesson) {
  return {
    type: CREATE_LESSON_SUCCESSFULLY,
    payload: lesson
  };
}

export function CreateLessonFailed(errMsg) {
  return {
    type: CREATE_LESSON_FAILED,
    errorMessage: errMsg
  };
}

export function UpdateLesson(id, lesson) {
  return {
    type: UPDATE_LESSON,
    payload: {
      lesson,
      id
    }
  };
}
export function UpdateLessonSuccessfully(lesson) {
  return {
    type: UPDATE_LESSON_SUCCESSFULLY,
    payload: lesson
  };
}

export function UpdateLessonFailed(errMsg) {
  return {
    type: UPDATE_LESSON_FAILED,
    errorMessage: errMsg
  };
}

export function GetLesson(id, params) {
  if (id)
    return {
      type: GET_LESSON,
      payload: id
    };
  return {
    type: GET_LESSON_SEARCH,
    payload: params
  };
}
export function GetLessonBySeo(id) {
  return {
    type: GET_LESSON_SEO,
    payload: id
  };
}

export function GetLessonByTag(tagName) {
  return {
    type: GET_LESSON_BY_TAG,
    payload: tagName
  };
}

export function GetLessonAll() {
  return {
    type: GET_LESSON_ALL
  };
}

export function GetMyLesson() {
  return {
    type: GET_MY_LESSON
  };
}

export function GetHomeLesson() {
  return {
    type: GET_LESSON_HOME
  };
}

export function SelectLesson(id) {
  return {
    type: SELECT_LESSON,
    payload: id
  };
}

export function GetLessonSuccessfully(lesson, type) {
  return {
    type: `${type}_SUCCESSFULLY`,
    payload: lesson
  };
}

export function ClearSelectedLesson() {
  return {
    type: CLEAR_SELECTED_LESSON
  };
}

export function GetLessonAllSuccessfully(lessonList) {
  return {
    type: GET_LESSON_ALL_SUCCESSFULLY,
    payload: lessonList
  };
}

export function GetLessonSearchSuccessfully(lessonList) {
  return {
    type: GET_LESSON_SEARCH_SUCCESSFULLY,
    payload: lessonList
  };
}

export function GetMyLessonSuccessfully(lessonList) {
  return {
    type: GET_MY_LESSON_SUCCESSFULLY,
    payload: lessonList
  };
}

export function GetLessonFailed(errMsg) {
  return {
    type: GET_LESSON_FAILED,
    errorMessage: errMsg
  };
}

export function SeenLesson(lesson) {
  return {
    type: SEEN_LESSON,
    payload: lesson
  };
}

export function SeenLessonSuccessfully() {
  return {
    type: SEEN_LESSON_SUCCESSFULLY
  };
}

export function SeenLessonFailed(errMsg) {
  return {
    type: SEEN_LESSON_FAILED,
    errorMessage: errMsg
  };
}

export function GetLessonDone(lesson) {
  return {
    type: GET_LESSON_DONE,
    payload: lesson
  };
}

export function GetLessonDoneSuccessfully(done) {
  return {
    type: GET_LESSON_DONE_SUCCESFULLY,
    payload: done
  };
}

export function GetLessonDoneFailed(errMsg) {
  return {
    type: GET_LESSON_DONE_FAILED,
    errorMessage: errMsg
  };
}
