// material
import { Button, Container, Skeleton, Stack, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetLessonByTag } from 'src/store/lesson/lesson.action';
import { useQuery } from 'src/utils/useQuery';
import Page from '../../components/Page';
import { RootStyle } from './CoursePreview/CoursePreview';
import ThirdCourseCard from './ThirdCourseCard';

export default function CourseByTagPage() {
  const [loadingCourses, setLoadingCourses] = useState(true);
  const navigate = useNavigate();
  const lessonState = useSelector((state) => state.lessons);

  const dispatch = useDispatch();

  const query = useQuery();

  const [tagName] = [query.get('chu-de')];

  useEffect(() => {
    dispatch(GetLessonByTag(tagName));
  }, []);

  useEffect(() => {
    setLoadingCourses(lessonState.loading);
  }, [lessonState]);

  return (
    <Page title="The Forum Education | Khoá học IELTS & SAT theo chủ đề chất lượng nhất">
      <RootStyle>
        <Container maxWidth="xl">
          <Typography variant="h3" pt={5}>
            {`Khóa học theo chủ đề ${tagName}`}
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            Rèn luyện kỹ năng theo các dạng bài
          </Typography>

          <Stack spacing={3}>
            {!loadingCourses ? (
              lessonState.data.lessonByTag?.length > 0 ? (
                lessonState.data.lessonByTag.map((lesson, index) => (
                  <ThirdCourseCard key={lesson._id} lessons={lesson} index={index} />
                ))
              ) : (
                <Typography variant="h1">
                  Rất tiếc, chủ đề bạn tìm kiếm chưa có :( hãy quay lại sau nhé!
                </Typography>
              )
            ) : (
              <Skeleton width="100%" height="40vh">
                <Typography>.</Typography>
              </Skeleton>
            )}
          </Stack>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ maxWidth: '200px', mt: 3 }}
            onClick={() => navigate('/')}
          >
            Trở về trang chủ
          </Button>
        </Container>
      </RootStyle>
    </Page>
  );
}
