import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import {
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UpdatePassword } from 'src/store/auth/updatepassword/updatepassword.action';
import * as Yup from 'yup';
import Page from '../Page';

const RootStyle = styled(Page)(({ theme }) => ({}));
const MyPassword = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);

  useEffect(() => {}, []);
  const PasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Vui lòng nhập mật khẩu hiện tại'),
    newPassword: Yup.string().required('Vui lòng nhập mật khẩu mới'),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'Mật khẩu mới chưa trùng khớp'
    )
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: PasswordSchema,
    onSubmit: () => {
      dispatch(UpdatePassword(values.currentPassword, values.newPassword));
      setSubmitting(false);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } =
    formik;

  return (
    <RootStyle>
      <FormikProvider value={formik}>
        <Typography component="h3" style={{ fontSize: 18, fontWeight: 'bold' }}>
          Đổi mật khẩu
        </Typography>
        <Divider />
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <TextField
              value={values.currentPassword}
              fullWidth
              autoComplete="password"
              placeholder="Mật khẩu hiện tại"
              type={showPassword ? 'text' : 'password'}
              label="Mật khẩu hiện tại"
              {...getFieldProps('currentPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.currentPassword && errors.currentPassword)}
              helperText={touched.currentPassword && errors.currentPassword}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2, mt: 5 }}
          >
            <TextField
              fullWidth
              autoComplete="new-password"
              type={showNewPassword ? 'text' : 'password'}
              label="Mật khẩu mới"
              placeholder="Mật khẩu mới"
              {...getFieldProps('newPassword')}
              error={Boolean(touched.newPassword && errors.newPassword)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setNewShowPassword((prev) => !prev)}>
                      <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              helperText={touched.newPassword && errors.newPassword}
            />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <TextField
              fullWidth
              autoComplete="confirm-new-password"
              type={showNewPassword ? 'text' : 'password'}
              label="Nhập lại mật khẩu mới"
              placeholder="Nhập lại mật khẩu mới"
              {...getFieldProps('confirmNewPassword')}
              error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
              helperText={touched.confirmNewPassword && errors.confirmNewPassword}
            />
          </Stack>

          <LoadingButton
            style={{ float: 'right' }}
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Cập nhật
          </LoadingButton>
        </Form>
      </FormikProvider>
    </RootStyle>
  );
};

export default MyPassword;
