import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import Page from '../Page';
import GeneralInfo from './GeneralInfo';
import AvatarInfo from './AvatarInfo';
import MyPassword from './MyPassword';

const RootStyle = styled(Page)(({ theme }) => ({
  maxWidth: '800px'
}));
const RightProfile = (props) => {
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const renderSelectedTab = () => {
    switch (props.activeTab) {
      case 'tab-info':
        return <GeneralInfo user={user} />;
      case 'tab-avatar':
        return <AvatarInfo />;
      case 'tab-password':
        return <MyPassword />;
      default:
        return <></>;
    }
  };

  return <RootStyle>{renderSelectedTab()}</RootStyle>;
};

RightProfile.propTypes = {
  user: PropTypes.object.isRequired,
  activeTab: PropTypes.string
};

export default RightProfile;
