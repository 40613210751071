// material
import { Box, Button, Container, Grid, Link, Skeleton, Stack, Typography } from '@material-ui/core';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import LessonTagDialog from 'src/components/LessonTag/LessonTagDialog';
import SupportDialog from 'src/components/Supports/SupportDialog';
import TopCourseCard from 'src/pages/Course/TopCourseCard';
import { GetHomeLesson } from 'src/store/lesson/lesson.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import Page from '../../components/Page';
import { Achivement } from '../About/Achivement';
import AboutSection from './AboutSection';
import IntroImage from './IntroImage';
import ReviewSection from './ReviewSection';
import SecondCourseCard from './SecondCourseCard';
import SupportSection from './SupportSection';
import ThirdCourseCard from './ThirdCourseCard';
import WhyLearningSection from './WhyLearningSection';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function CoursesHome() {
  const [loadingCourses, setLoadingCourses] = useState(true);
  const lessonState = useSelector((state) => state.lessons);
  const dispatch = useDispatch();

  useEffect(() => {
    Aos.init({
      duration: 2200
    });
    dispatch(GetHomeLesson());
  }, []);

  useEffect(() => {
    setLoadingCourses(lessonState.loading);
  }, [lessonState]);

  return (
    <Page
      title="The Forum Education | Cung cấp khoá học IELTS & SAT chất lượng nhất"
      sx={{ paddingBottom: 0 }}
    >
      <IntroImage />

      <Box sx={{ py: { md: 15, xs: 15 } }} />

      <Container maxWidth="xl">
        <Stack justifyContent="space-between" marginBottom={3}>
          <Typography variant="h3" id="courses" data-aos="fade-down">
            Combo Siêu Tiết Kiệm
          </Typography>
          {/* <Typography variant="body1" marginBottom={2} data-aos="fade-right">
            Gợi ý cho bạn
          </Typography> */}
          {/* <BlogPostsSearch
            posts={lessonState.data.list.level1}
            field="name"
          /> */}
          {/* <BlogPostsSort options={SORT_OPTIONS} /> */}
        </Stack>
        <Grid container spacing={3} marginBottom={5} justifyContent={{ md: 'start', lg: 'center' }}>
          {!loadingCourses ? (
            lessonState.data.list.level1?.map((lesson, index) => (
              <TopCourseCard key={lesson._id} lessons={lesson} index={index} />
            ))
          ) : (
            <Skeleton width="100%" height="50vh">
              <Typography>.</Typography>
            </Skeleton>
          )}
        </Grid>
        <Box my={10} />
        {/* <Typography variant="body1" marginBottom={2} data-aos="fade-right">
          Nâng cao tất cả kỹ năng
        </Typography> */}
        <Stack justifyContent="space-between" marginBottom={3}>
          <Typography variant="h3" pt={5} data-aos="fade-down">
            Combo Toàn Diện
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          {!loadingCourses ? (
            lessonState.data.list.level2?.map((lesson, index) => (
              <SecondCourseCard key={lesson._id} lessons={lesson} index={index} />
            ))
          ) : (
            <Skeleton width="100%" height="40vh">
              <Typography>.</Typography>
            </Skeleton>
          )}
        </Grid>

        <Stack justifyContent="space-between" marginBottom={3}>
          <Typography variant="h3" pt={5} data-aos="fade-down">
            Khóa Riêng Từng Kỹ Năng
          </Typography>
        </Stack>

        {/* <Typography variant="body1" marginBottom={2}>
          Rèn luyện từng kỹ năng
        </Typography> */}
        <Stack spacing={3}>
          {!loadingCourses ? (
            lessonState.data.list.level3?.map((lesson, index) => (
              <ThirdCourseCard key={lesson._id} lessons={lesson} index={index} />
            ))
          ) : (
            <Skeleton width="100%" height="40vh">
              <Typography>.</Typography>
            </Skeleton>
          )}
          <Link
            component={RouterLink}
            underline="none"
            to={UrlConfigs.ALL_COURSES}
            sx={{ pt: 2, m: '0 auto', textAlign: 'center' }}
          >
            <Button fullWidth sx={{ maxWidth: '150px' }} target="_blank" variant="outlined">
              Xem thêm
            </Button>
          </Link>
        </Stack>
        <Box my={10} />
        <AboutSection />
        <Box my={10} />
        <WhyLearningSection />
        <Box my={20} />
        <Typography
          variant="h2"
          p={5}
          textAlign="center"
          alignSelf="center"
          flex="5"
          data-aos="fade-down"
        >
          Thành tích học viên tại The Forum
        </Typography>
        <Achivement />
        <Box my={10} />
        <div data-aos="fade-down">
          <ReviewSection />
        </div>
        <Box my={10} />
      </Container>

      <SupportSection />

      <SupportDialog />

      {/* <LessonTagDialog /> */}
    </Page>
  );
}
