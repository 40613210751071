import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UrlConfigs } from 'src/utils/UrlConfig';

const SeoAuthPage = () => {
  const [next, setNext] = useState(false);

  if (next) return <Navigate replace to={UrlConfigs.COURSES} />;

  return (
    <>
      <Dialog fullWidth open>
        <DialogTitle>Chào Mừng Bạn Đến Với The Forum</DialogTitle>
        <DialogContent>Tiếp tục để khám phá các khóa học IELTS chất lượng nhất!</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setNext(true);
            }}
          >
            Tiếp tục
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SeoAuthPage;
