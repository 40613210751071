export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESSFULLY = 'GET_USER_SUCCESSFULLY';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_SUCCESSFULLY = 'UPDATE_CURRENT_USER_SUCCESSFULLY';
export const UPDATE_CURRENT_USER_FAILED = 'UPDATE_CURRENT_USER_FAILED';

export function GetUser(setUser) {
  return {
    type: GET_USER,
    payload: {
      setUser
    }
  };
}

export function GetUserSuccessfully(user) {
  return {
    type: GET_USER_SUCCESSFULLY,
    payload: user
  };
}

export function GetUserFailed(errMsg) {
  return {
    type: GET_USER_FAILED,
    errorMessage: errMsg
  };
}

export function UpdateCurrentUser({ photo, firstName, lastName, phone }) {
  return {
    type: UPDATE_CURRENT_USER,
    payload: { photo, firstName, lastName, phone }
  };
}

export function UpdateCurrentUserSuccessfully(user) {
  return {
    type: UPDATE_CURRENT_USER_SUCCESSFULLY,
    payload: user
  };
}

export function UpdateCurrentUserFailed(errMsg) {
  return {
    type: UPDATE_CURRENT_USER_FAILED,
    errorMessage: errMsg
  };
}
