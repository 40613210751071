// material
import { Stack, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { LoadingButton } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import * as Yup from 'yup';
import { CreateLessonTopic } from '../../store/lesson-topic/lesson-topic.action';

// ----------------------------------------------------------------------

export const HoverButton = styled('div')(() => ({
  flexGrow: 1,
  cursor: 'pointer',
  opacity: 0,
  position: 'absolute',
  bottom: 0,
  left: 0,
  transform: 'translate(-25px, 15px)',
  '@media screen and (max-width: 960px)': {
    transition: 'all 0.2s ease-in-out',
    fontSize: '1rem',
    color: 'white'
  },
  '&:hover': {
    transition: 'all 0.2s ease-in-out',
    opacity: 1
  }
}));

export const HoverVideoAddButton = styled(AddIcon)(() => ({
  cursor: 'pointer',
  opacity: 0,
  position: 'absolute',
  bottom: '5px',
  left: '30px',
  '@media screen and (max-width: 960px)': {
    transition: 'all 0.2s ease-in-out',
    fontSize: '1rem',
    color: 'white'
  },
  '&:hover': {
    transition: 'all 0.2s ease-in-out',
    opacity: 1
  }
}));

const AddVideoForm = ({ lesson }) => {
  const dispatch = useDispatch();
  const navigationState = useSelector((state) => state.navigation);
  const lessonTopicsState = useSelector((state) => state.lessonTopics);

  const CreateLessonTopicSchema = Yup.object().shape({
    playlistId: Yup.string().required('Folder/Playlist Id is required')
  });

  const formik = useFormik({
    initialValues: {
      playlistId: ''
    },
    validationSchema: CreateLessonTopicSchema,
    enableReinitialize: true,
    onSubmit: ({ playlistId }) => {
      // DO AUTH
      // navigate('/courses', { replace: true });
      dispatch(CreateLessonTopic(lesson._id, playlistId));
    }
  });
  const { isSubmitting, errors, touched, handleSubmit, getFieldProps } = formik;

  if (navigationState.redirectTo) {
    return <Navigate to={navigationState.redirectTo} />;
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} style={{ position: 'relative' }}>
          <TextField
            fullWidth
            autoComplete="c1"
            type="text"
            label="Playlist Youtube Id"
            {...getFieldProps(`playlistId`)}
            error={Boolean(touched.playlistId && errors.playlistId)}
            helperText={touched.playlistId && errors.playlistId}
          />
        </Stack>
        <LoadingButton
          sx={{ my: 3 }}
          size="large"
          variant="contained"
          type="submit"
          loading={isSubmitting && lessonTopicsState.loading}
        >
          Lưu thông tin
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};
AddVideoForm.propTypes = {
  lesson: PropTypes.object
};
export default AddVideoForm;
