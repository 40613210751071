import { Box, Button, ButtonGroup, Slider, Stack, Typography } from '@material-ui/core';
import {
  Forward5Outlined,
  FullscreenOutlined,
  PauseCircleOutline,
  VolumeDownOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined
} from '@material-ui/icons';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import { displayYoutubeTime } from 'src/utils/formatTime';

const CUSTOM_YOUTUBE_CONTROL = true;

const valueLabelFormat = (value) => displayYoutubeTime(value);

const LinearProgressWithLabel = (props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      transform: 'translate(-0%, -112%)',
      zIndex: 2,
      height: 43,
      background: '#000000a3'
    }}
  >
    <Box sx={{ width: '95%', mr: 1, ml: '15px' }}>
      {/* <LinearProgress variant="determinate" {...props} onChange={props.handleChange} /> */}
      <Slider
        value={props.youtube ? props.youtube.getCurrentTime() : props.forceRerender}
        min={0}
        step={1}
        max={props.youtube ? props.youtube.getDuration() : 5 * 60}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={props.handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
      />
    </Box>
    <Box sx={{ width: 100 }}>
      <Typography variant="body2" color="text.secondary">
        {!props.youtube
          ? 5 * 60
          : `${displayYoutubeTime(props.youtube.getCurrentTime())}/${displayYoutubeTime(
              props.youtube.getDuration()
            )}`}
      </Typography>
    </Box>
  </Box>
);

export const YoutubeVideoPlayer = ({ playerSrc }) => {
  /**
   * -1 (unstarted)
   * 0 (ended)
   * 1 (playing)
   * 2 (paused)
   * 3 (buffering)
   * 5 (video cued).
   */
  const [youtubeState, setYoutubeState] = useState();
  const [forceRerender, setForceRerender] = useState(0);
  const [mute, setMute] = useState();
  const [youtube, setYoutube] = useState();
  const [volumn, setVolumn] = useState(0);

  const youtubeRef = useRef();

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    // console.log(event.target);
    setMute(event.target.isMuted());
    event.target.setVolume(50);
    setVolumn(50);
    setYoutube(event.target);
  };

  const _onPlay = (_youtube) => {
    if (_youtube && _youtube.playVideo) _youtube.playVideo();
  };

  const _debouncePlay = useCallback(throttle(_onPlay, 300), []);

  const handleStateChange = (event) => {
    if (event.data === -1) {
      youtube.playVideo();
    }
    if (event.data === 1) document.querySelector('.video-youtube').classList.add('played');
    setYoutubeState(event);
  };

  const handleVolumn = (event, newValue) => {
    setVolumn(newValue);
    youtube.setVolume(newValue);
  };

  const handleYoutubeSeek = (event, newValue) => {
    // setProgress(newValue);
    youtube.seekTo(newValue);
    _debouncePlay(youtube);
  };

  useEffect(() => {
    if (!youtube) return;
    if (mute) youtube.mute();
    else youtube.unMute();
  }, [mute]);

  useEffect(() => {
    if (!youtubeState || !youtube) return;
    let timer;
    if (youtubeState.data === 1) {
      timer = setInterval(() => {
        setForceRerender(youtube.getCurrentTime());
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [youtubeState, youtube]);

  return (
    <Stack id="youtube-wrapper">
      <YouTube
        className={`w-100 mh-50 ${CUSTOM_YOUTUBE_CONTROL ? 'video-youtube' : ''}`}
        videoId={playerSrc}
        ref={youtubeRef}
        opts={{
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            color: 'white',
            enablejsapi: 1,
            // modestbranding: 1,
            controls: CUSTOM_YOUTUBE_CONTROL ? 0 : 1,
            rel: 0
            // loop: 1,
            // playlist: playerSrc
          }
        }}
        onStateChange={handleStateChange}
        onReady={_onReady}
      />
      {CUSTOM_YOUTUBE_CONTROL && (
        <LinearProgressWithLabel
          youtube={youtube}
          handleChange={handleYoutubeSeek}
          forceRerender={forceRerender}
        />
      )}
      {youtubeState && CUSTOM_YOUTUBE_CONTROL && (
        <Stack
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row'
            },
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: '1500px',
            margin: '0 auto'
          }}
        >
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            sx={{ justifyContent: 'center', flexWrap: 'wrap' }}
          >
            <Button
              onClick={() => {
                youtube.seekTo(youtube.getCurrentTime() - 5);
              }}
            >
              <Forward5Outlined sx={{ transform: 'scaleX(-1)' }} />
            </Button>
            <Button
              onClick={() => {
                youtubeState.data === 1 ? youtube.pauseVideo() : youtube.playVideo();
              }}
            >
              {youtubeState.data === 1 ? <PauseCircleOutline /> : <PlayCircleOutline />}
            </Button>
            <Button
              onClick={() => {
                youtube.seekTo(youtube.getCurrentTime() + 5);
              }}
            >
              <Forward5Outlined />
            </Button>
            <Button
              onClick={() => {
                youtube.playVideo(); // won't work on mobile
                const iframe = document.getElementsByClassName('video-youtube')[0];
                const requestFullScreen =
                  iframe.requestFullScreen ||
                  iframe.mozRequestFullScreen ||
                  iframe.webkitRequestFullScreen;
                if (requestFullScreen) {
                  requestFullScreen.bind(iframe)();
                }
              }}
            >
              <FullscreenOutlined />
            </Button>
            <Button
              onClick={() => {
                setMute(!mute);
              }}
            >
              {mute ? <VolumeOffOutlined /> : <VolumeUpOutlined />}
            </Button>
          </ButtonGroup>

          <Box sx={{ width: 200 }}>
            <Stack spacing={2} direction="row" alignItems="center" height="100%">
              <VolumeDownOutlined />
              <Slider
                aria-label="Volume"
                value={volumn}
                valueLabelDisplay="auto"
                step={10}
                min={0}
                max={100}
                onChange={handleVolumn}
              />
              <VolumeUpOutlined />
            </Stack>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default YoutubeVideoPlayer;
