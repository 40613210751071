import { defer, Observable } from 'rxjs';
import * as auth from 'firebase/auth';
import * as store from 'firebase/storage';
import * as firestore from 'firebase/firestore';

export function loginWithGoogle() {
  return defer(() => {
    const provider = new auth.GoogleAuthProvider();
    return auth.signInWithPopup(auth.getAuth(), provider);
  });
}

export function loginWithPassword(email, password, isRemembered) {
  return defer(async () => {
    await auth.setPersistence(
      auth.getAuth(),
      isRemembered ? auth.browserLocalPersistence : auth.browserSessionPersistence
    );
    return auth.signInWithEmailAndPassword(auth.getAuth(), email, password);
  });
}
export function logout() {
  return defer(() => auth.signOut(auth.getAuth()));
}

export function isSigned() {
  return new Observable((subscriber) =>
    auth.getAuth().onAuthStateChanged(
      (user) => subscriber.next(user),
      (error) => subscriber.error(error),
      () => subscriber.complete()
    )
  );
}

export function createUserWithEmailAndPassword(email, password) {
  return defer(() => auth.createUserWithEmailAndPassword(auth.getAuth(), email, password));
}

export function updateDisplayname(displayName) {
  return defer(() => auth.updateProfile(auth.getAuth().currentUser, { displayName: displayName }));
}
export function hotupdateDisplayname(displayName) {
  return auth.updateProfile(auth.getAuth().currentUser, { displayName: displayName });
}

export function getCurrentUser() {
  return defer(() => auth.getAuth().currentUser);
}

export async function getIdToken() {
  // eslint-disable-next-line no-return-await
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(
      auth.getAuth(),
      async (user) => {
        unsubscribe();
        const refreshedToken = user
          ? await user.getIdToken(true).catch((err) => console.error(err))
          : '';
        resolve(refreshedToken);
      },
      reject
    );
  });
}

export function reAuthen(currentPassword) {
  const { currentUser } = auth.getAuth();
  const credential = auth.EmailAuthProvider.credential(currentUser.email, currentPassword);
  return defer(() => auth.reauthenticateWithCredential(currentUser, credential));
}

export function changePassword(newPassword) {
  const { currentUser } = auth.getAuth();
  return defer(() => auth.updatePassword(currentUser, newPassword));
}

export async function sendPasswordResetEmail(email) {
  return auth.sendPasswordResetEmail(auth.getAuth(), email);
}

export async function uploadFile(file, path = null, filename = null) {
  const { currentUser } = auth.getAuth();
  const newPath = path
    ? `${path}/${currentUser.uid}_${Date.now()}_${filename || file.name}`
    : `${currentUser.uid}/${Date.now()}_${filename || file.name}`;

  const ref = store.ref(store.getStorage(), newPath);
  const taskResult = await store.uploadBytes(ref, file);
  const url = await store.getDownloadURL(taskResult.ref);
  return url;
}

export async function getFile(path) {
  const ref = store.ref(store.getStorage(), path);
  const url = await store.getDownloadURL(ref);
  return url;
}

export async function setDocuemt(path, docname, data) {
  const ref = firestore.doc(firestore.getFirestore(), path, docname);
  const previousDoc = await getDocument(ref);
  console.log(previousDoc.data());
  const response = await firestore.setDoc(
    ref,
    {
      ...data,
      last_updated: firestore.serverTimestamp(),
      count: previousDoc.exists() ? previousDoc.data().count + 1 : 0
    },
    { merge: true }
  );
  return response;
}

export async function getDocument(docRef) {
  return firestore.getDoc(docRef);
}
