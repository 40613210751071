import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Link,
  Stack,
  Typography
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { logEvent } from 'firebase/analytics';
// material
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { timer } from 'rxjs';
import { setDocuemt } from 'src/apis/firebase.api';
import SupportDialog from 'src/components/Supports/SupportDialog';
import YoutubeVideoPlayer from 'src/components/videoplayer/YoutubePlayer';
import { LESSON_PAYMENT_PAID } from 'src/constants/constant';
import { analytics } from 'src/network/firebase';
import { HandleLoginPopup } from 'src/store/auth/signin/signin.action';
import { GetLessonBySeo } from 'src/store/lesson/lesson.action';
import { VerifyLesson } from 'src/store/payment/payment.action';
import { showSuccess } from 'src/utils/toast';
import { makeVideoSrc, makeVideoSrcYoutube } from 'src/utils/trimtitle';
import { UrlConfigs } from 'src/utils/UrlConfig';
import Page from '../../../components/Page';
import { CourseTabs } from '../CourseTabs';
import '../iframe.css';
import ThirdCourseCard from '../ThirdCourseCard';
import { handlePopoverClose, handlePopoverOpen } from '../TopCourseCard';
import CoursePreviewInfo from './CoursePreviewInfo';
import { LoadingCoursePreview } from './LoadingCoursePreview';

const USE_YOTUBE = true;
// ----------------------------------------------------------------------

export const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  marginInline: 5,
  paddingTop: '70px',
  paddingBottom: theme.spacing(5)
}));

const TabStyle = styled('div')(({ theme }) => ({
  maxWidth: '1500px',
  margin: '0 auto',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5)
}));

const CoursePreview = () => {
  const { id } = useParams();
  const lessonState = useSelector((state) => state.lessons);
  const payment = useSelector((state) => state.payment);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  const [lessonStateLocal, setLessonStateLocal] = useState();
  const [loading, setLoading] = useState(true);
  const [paid, setPaid] = useState(false);
  const [hocthu, setHocthu] = useState(false);

  const [selectedVideo, setSelectedVideo] = useState();
  const [playerSrc, setPlayerSrc] = useState();
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [tab, setTab] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetLessonBySeo(id));
    localStorage.setItem('last-id-selected', id);
  }, []);

  useEffect(() => {
    if (auth.isAuthorized) {
      dispatch(VerifyLesson(id));
    }
  }, [auth]);

  useEffect(() => {
    setLoading(lessonState.loading);
    if (lessonState.data.selected) {
      setLessonStateLocal(lessonState.data.selected);
      logEvent(analytics, `course${lessonState.data.selected.name}_clicked`);
    }
  }, [payment, lessonState]);

  useEffect(() => {
    if (!loading && payment.lessonPaid === LESSON_PAYMENT_PAID) {
      setHocthu(false);

      if (!auth.isAuthorized) {
        setPlayerSrc(null);
        document.getElementById('video-player')?.classList.remove('paid');
        setPaid(false);
      } else {
        setPaid(true);
        setTab(1);
        document.getElementById('video-player')?.classList.add('paid');

        const lastVideo = JSON.parse(localStorage.getItem(`last-video-${id}`));
        if (lastVideo) {
          setSelectedTopic(lastVideo.topic);
          setSelectedVideo(lastVideo.video);
          setPlayerSrc(lastVideo.src);
        } else setFirstVideo();
      }
    }
    // if (!auth.isAuthorized && playerSrc) window.location.reload();
  }, [payment, auth, loading]);

  useEffect(() => {
    if (paid) return;
    if (USE_YOTUBE) return;

    if (playerSrc) {
      if (!auth.isAuthorized) {
        dispatch(HandleLoginPopup());
        setHocthu(true);
        setPlayerSrc();
      }
      const timeout = setTimeout(() => {
        const script = document.createElement('script');
        script.id = 'sprout-plugin';
        script.src = 'https://c.sproutvideo.com/lightbox/v1.0.0.js';
        script.async = true;
        document.body.appendChild(script);
      }, 200);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [playerSrc]);

  useEffect(() => {
    if (hocthu && user.data.current) {
      setFirstVideo();
      /** Scroll to video */
      const scrollDiv = document.getElementById('youtube-wrapper').offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
      // TELESALE
      logEvent(analytics, `course${lessonState.data.selected.name}_hocthu`);
      const setDocumentCallback = setDocuemt('hocthu', user.data.current.accountId, {
        ...user.data.current,
        last_course: lessonState.data.selected.name
      });
      setDocumentCallback.then(() => {
        showSuccess('Hãy khám phá thêm những bài học thử khác ở mục nội dung!');
      });
    }
  }, [auth, hocthu]);

  const setFirstVideo = () => {
    setSelectedVideo(0);
    const makeSrc = USE_YOTUBE ? makeVideoSrcYoutube : makeVideoSrc;
    lessonState.data.selected?.lessonTopics.length > 0 &&
      setPlayerSrc(makeSrc(lessonState.data.selected?.lessonTopics[0].lessonVideos[0]));
  };

  const handleHocThu = () => {
    setHocthu(true);
    setFirstVideo();
  };

  const changeVideo = (video, selectidx, index) => {
    const makeSrc = USE_YOTUBE ? makeVideoSrcYoutube : makeVideoSrc;
    const newplayerSrc = makeSrc(video);

    // if (playerSrc === newplayerSrc) return;

    setLoading(true);
    // Set DONE when user change clip
    // dispatch(
    //   SeenLesson({
    //     lessonId: id,
    //     lessonVideoId: index
    //   })
    // );
    localStorage.setItem(
      `last-video-${id}`,
      JSON.stringify({
        src: newplayerSrc,
        topic: selectedTopic,
        video: selectidx
      })
    );
    setPlayerSrc(newplayerSrc);
    setSelectedVideo(selectidx);
    const subscription = timer(100).subscribe(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    });

    return () => {
      subscription.unsubscribe();
    };
  };

  return (
    <>
      <RootStyle
        title={`The Forum Education | Khoá học ${
          lessonStateLocal?.name || 'IELTS/SAT chất lượng nhất'
        }`}
      >
        <Stack
          direction={{
            xs: 'column-reverse',
            sm: 'column-reverse',
            md: 'column-reverse',
            lg: 'row'
          }}
          spacing={2}
          justifyContent="center"
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: `${paid ? 'unset' : '1000px'}` /** alignSelf: 'center' */
            }}
          >
            {paid && (
              <Typography
                gutterBottom
                color="primary.main"
                variant="h3"
                component="div"
                textAlign="center"
              >
                {lessonStateLocal?.name}
              </Typography>
            )}
            {!loading && lessonStateLocal ? (
              <>
                {/* VIDEO PLAYER HERE! */}
                {/* <VideoPlayer options={playerConfig} /> */}
                {!playerSrc && (
                  <CardMedia
                    component="img"
                    alt="The Forum Course"
                    title={lessonStateLocal.introduction}
                    image={lessonStateLocal.thumbnail}
                    sx={{
                      width: '80%',
                      margin: '0 auto',
                      background: 'lightgray',
                      objectFit: 'contained',
                      minHeight: {
                        md: '30em'
                      },
                      transition: 'all .5s'
                    }}
                    loading="lazy"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    onClick={handleHocThu}
                  />
                )}
                {/* {playerSrc && paid && <PaidPlayer playerSrc={playerSrc} />}
                {playerSrc && !paid && <UnPaidPlayer playerSrc={playerSrc} />} */}
                {playerSrc && <YoutubeVideoPlayer playerSrc={playerSrc} />}
                <TabStyle id="detail">
                  <CourseTabs
                    lesson={lessonStateLocal}
                    auth={auth}
                    changeVideo={changeVideo}
                    selectedVideo={selectedVideo}
                    tab={tab}
                    setTab={setTab}
                    selectedTopic={selectedTopic}
                    setSelectedTopic={setSelectedTopic}
                    paid={paid}
                  />
                </TabStyle>
              </>
            ) : (
              <LoadingCoursePreview />
            )}
          </Box>

          {!loading && lessonStateLocal && !paid && (
            <CoursePreviewInfo
              trialClick={handleHocThu}
              isAuthorized={auth.isAuthorized}
              currentUser={user.data}
              lessonState={lessonStateLocal}
            />
          )}
        </Stack>
        {/* Suggest Lesson */}
        {!loading && !paid && (
          <Stack
            direction={{
              xs: 'column-reverse',
              sm: 'column-reverse',
              md: 'column-reverse',
              lg: 'row'
            }}
            spacing={2}
            justifyContent="center"
            ml={{ lg: 0, xl: 5 }}
          >
            <Container maxWidth="xl">
              <Typography variant="h3" id="courses">
                Khóa học tương tự
              </Typography>
              <Typography variant="body1" marginBottom={2}>
                Mọi người cũng tìm kiếm
              </Typography>
              <Stack spacing={3}>
                {lessonState.data.list[`level${lessonStateLocal && lessonStateLocal.sellLevel}`]
                  ?.filter((lesson) => lesson._id !== lessonStateLocal._id)
                  .map((lesson, index) => (
                    <ThirdCourseCard
                      key={`khoa-hoc-tuong-tu-${lesson._id}`}
                      lessons={lesson}
                      index={index}
                      onClick={() => {
                        console.log('Clicked');
                        setLoading(true);
                      }}
                    />
                  ))}

                <Link
                  component={RouterLink}
                  underline="none"
                  to={UrlConfigs.ALL_COURSES}
                  sx={{ pt: 2, m: '0 auto', textAlign: 'center' }}
                >
                  <Button fullWidth sx={{ maxWidth: '150px' }} target="_blank" variant="outlined">
                    Xem thêm
                  </Button>
                </Link>
              </Stack>
            </Container>
            <Card
              id="summary"
              sx={{
                maxWidth: '80vw',
                width: '200px',
                height: '100%',
                margin: 'auto',
                alignSelf: { xs: 'center', lg: 'unset' }
              }}
            />
          </Stack>
        )}
      </RootStyle>

      <SupportDialog />
    </>
  );
};
export default CoursePreview;
