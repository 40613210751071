export const LOGIN = 'LOGIN';
export const LOGIN_GOOLE = 'LOGIN_GOOLE';
export const LOGIN_SUCCESSFULLY = 'LOGIN_SUCCESSFULLY';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const TOGGLE_LOGIN_POPUP = 'TOGGLE_LOGIN_POPUP';

export function Login(email, password, remembered = false) {
  return {
    type: LOGIN,
    payload: {
      email,
      password,
      remembered
    }
  };
}

export function LoginWithGoogle() {
  return {
    type: LOGIN_GOOLE
  };
}

export function LoginSuccessful(data) {
  return {
    type: LOGIN_SUCCESSFULLY,
    payload: data
  };
}

export function LoginFailed(errMsg) {
  return {
    type: LOGIN_FAILED,
    errorMessage: errMsg
  };
}

export function HandleLoginPopup() {
  return {
    type: TOGGLE_LOGIN_POPUP
  };
}
