import { defer } from 'rxjs';
import http from '../network/http';

/**
 *
 * @param {{
 * code: string
 * }} coupon
 * @returns
 */
export function verifyCoupon(coupon) {
  return defer(() => http.post('/v1/coupon/verify', coupon));
}

export function getCoupon(code) {
  return defer(() => http.get(`/v1/coupon/${code}`));
}
