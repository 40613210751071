import { Box, Container, Step, StepLabel, Stepper } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useState } from 'react';
import CodDialog from 'src/components/COD/CodDialog';
import Page from 'src/components/Page';
import SupportDialog from 'src/components/Supports/SupportDialog';
import QontoConnector from './Oonto/OontoConector';
import QontoStepIcon from './Oonto/QontoStepIcon';
import { PaymentForm } from './PaymentSummary';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  marginInline: 5,
  paddingTop: '70px',
  paddingBottom: theme.spacing(5)
}));

const steps = ['Chọn khóa học', 'Chọn phương thức thanh toán', 'Nhận khóa học'];
// ----------------------------------------------------------------------

export default function Payment() {
  const [step, setStep] = useState(1);

  return (
    <RootStyle title="The Forum Education | Thanh toán">
      <Container maxWidth="lg">
        <Box marginBottom={5}>
          <Stepper alternativeLabel activeStep={step} connector={<QontoConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <PaymentForm setStep={setStep} />
      </Container>

      <SupportDialog />

      <CodDialog />
    </RootStyle>
  );
}
