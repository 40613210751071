import { Box, Button, Drawer, Stack, Typography } from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { MHidden } from '../components/@material-extend';
// components
import Logo from '../components/Logo';
import NavSection from '../components/NavSection';
import Scrollbar from '../components/Scrollbar';
//
import sidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 330;

const RootStyle = styled('div')(({ theme }) => ({
  marginTop: 64,
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0
  }
}));

// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[200]
// }));

// ----------------------------------------------------------------------

Sidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function Sidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, pt: 3, textAlign: 'center' }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, textAlign: 'center' }}>
        <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
          The Forum Education
        </Typography>
      </Box>

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 5 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/TFC.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              IELTS Cấp tốc
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Chỉ từ{' '}
              <span>
                <strike> 990.000đ </strike>
                {'\n'}
                <span style={{ color: 'red', fontSize: '1.4rem', fontWeight: 'bold' }}>
                  770.000đ
                </span>
              </span>
              /tháng
            </Typography>
          </Box>

          <Button
            fullWidth
            onClick={() => navigate('../detail/613a0ae5070c3e52743d098a')}
            target="_blank"
            variant="contained"
          >
            Tìm hiểu ngay
          </Button>
        </Stack>
      </Box>
      <Box sx={{ px: 2.5, pb: 3, mt: 5 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/TFC.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Khoá học IELTS Giảm giá
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Chỉ từ{' '}
              <span>
                <strike> 600.000đ </strike>
                {'\n'}
                <span style={{ color: 'red', fontSize: '1.4rem', fontWeight: 'bold' }}>
                  499.000đ
                </span>
                /tháng
              </span>
            </Typography>
          </Box>

          <Button
            fullWidth
            onClick={() => navigate('../detail/613a200ab7dcb85f2413e076')}
            target="_blank"
            variant="contained"
          >
            Tìm hiểu ngay
          </Button>
        </Stack>
      </Box> */}
      {/* 
      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}

      <NavSection navConfig={sidebarConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Drawer
        open={isOpenSidebar}
        anchor="left"
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { width: DRAWER_WIDTH }
        }}
      >
        {renderContent}
      </Drawer>
      {/* <MHidden width="lgDown">
        <Drawer
          open
          anchor="right"
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
              // borderRight: 'unset'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden> */}
    </RootStyle>
  );
}
