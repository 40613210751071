import { TextField } from '@material-ui/core';
import { LocalizationProvider, MobileDatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

export const DatePicker = ({ date, handleDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label="Ngày hết hạn"
        inputFormat="MM/dd/yyyy"
        value={date}
        onChange={handleDate}
        renderInput={(params) => <TextField {...params} />}
        disablePast
      />
    </LocalizationProvider>
  );
};
