import { BottomNavigationAction, Fab, Icon } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MotionContainer } from 'src/components/animate';
import { ToggleDialogSupport } from 'src/store/dialogs/dialog.action';
import { transitionValues } from '../animate/MotionContainer';
import { LogoBottomStyle } from './BottomNavigateContact';

const BottomNavigationActionStyle = styled(BottomNavigationAction)({
  position: 'fixed',
  right: '12px',
  zIndex: '2'
});

export const DesktopContact = () => {
  const dispatch = useDispatch();
  return (
    <>
      <BottomNavigationActionStyle
        sx={{
          bottom: '350px'
        }}
        icon={
          <Fab
            aria-label="Liên hệ The Forum qua Điện Thoại"
            sx={{
              width: '80px',
              height: '80px'
            }}
          >
            <Icon sx={{ color: '#6e6e6e' }} className="md-70">
              sms
            </Icon>
          </Fab>
        }
        onClick={() => {
          dispatch(ToggleDialogSupport());
        }}
      />
      <BottomNavigationActionStyle
        sx={{
          bottom: '200px'
        }}
        icon={
          <MotionContainer initial="initial" open>
            <motion.div
              variants={{
                animate: {
                  scale: ['0.98', '0.98', '1.3'],
                  transition: transitionValues
                }
              }}
            >
              <Fab
                aria-label="Liên hệ The Forum qua Điện Thoại"
                sx={{
                  bottom: '-3.1em',
                  left: '-0em',
                  width: '80px',
                  height: '80px',
                  position: 'fixed',
                  backgroundColor: '#fff57a55'
                }}
              />
            </motion.div>
            <Fab
              href="tel:1900886640"
              aria-label="Liên hệ The Forum qua Điện Thoại"
              color="primary"
              sx={{
                bottom: '2.5em',
                width: '80px',
                height: '80px',
                backgroundColor: '#ff7171'
              }}
            >
              <motion.div
                variants={{
                  animate: {
                    rotate: ['0deg', '15deg', '30deg'],
                    transition: transitionValues
                  }
                }}
              >
                <Icon sx={{ color: '#fff57a' }} className="md-70">
                  phone_in_talk
                </Icon>
              </motion.div>
            </Fab>
          </MotionContainer>
        }
      />
      <BottomNavigationActionStyle
        sx={{
          bottom: '100px'
        }}
        target="_blank"
        href="https://zalo.me/0868537506"
        label="Zalo"
        icon={
          <LogoBottomStyle
            style={{
              height: '75px'
            }}
            src="/static/icons/zalo.png"
            alt="Liên hệ The Forum qua Zalo"
          />
        }
      />
    </>
  );
};
