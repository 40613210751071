import { Card, Container, Link, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { MHidden } from '../../components/@material-extend';
import CreateLessonFrom from '../../components/manager/CreateLessonForm';
// components
import Page from '../../components/Page';
import AuthLayout from '../../layouts/AuthLayout';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 300,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 1000,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function CreateLesson() {
  return (
    <RootStyle title="The Forum Education | Đăng nhập">
      <AuthLayout>
        Trở về&nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/courses">
          Trang chủ
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h4" sx={{ px: 5, mb: 5 }}>
            Tạo khoá học
          </Typography>
          <img src="/static/illustrations/illustration_register.png" alt="create_lesson" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Tạo khoá học
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Hãy điền thông tin vào bên dưới nhé!
            </Typography>
          </Stack>

          <CreateLessonFrom />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
