/* eslint-disable jsx-a11y/label-has-associated-control */
// material
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Link,
  FormControl,
  Typography
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { uploadFile } from 'src/apis/firebase.api';
import { LEVEL, SELL_LEVEL } from 'src/constants/constant';
import { CreateLesson, UpdateLesson } from 'src/store/lesson/lesson.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import * as Yup from 'yup';
import { getParentLessons } from '../../apis/lesson.api';
import { GetCategories } from '../../store/category/category.action';
import FileUpload from '../@material-extend/FileUpload';
import './CKEditor.css';

// ----------------------------------------------------------------------

const CreateLessonTag = ({ lesson }) => {
  const dispatch = useDispatch();

  const LessonSchema = Yup.object().shape({
    tagName: Yup.string().required('Vui lòng điền tên tag khóa học')
  });

  const formik = useFormik({
    initialValues: {
      tagName: ''
    },
    validationSchema: LessonSchema,
    enableReinitialize: true,
    onSubmit: () => {
      resetAll();
    }
  });

  const resetAll = () => {
    resetForm();
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    handleChange,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    resetForm,
    dirty
  } = formik;
  useEffect(() => {
    dispatch(GetCategories());
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Stack spacing={3}>
          {lesson && (
            <Link
              underline="none"
              variant="subtitle2"
              component={RouterLink}
              to={`/khoa-hoc/${lesson.seoTitle}`}
            >
              Xem khóa học
            </Link>
          )}

          <Typography textAlign="center" variant="h2">
            {lesson.name}
          </Typography>

          <Typography variant="body">Tên tag</Typography>

          <TextField
            fullWidth
            autoComplete="course-introduction"
            type="text"
            {...getFieldProps('tagName')}
            error={Boolean(touched.tagName && errors.tagName)}
            helperText={touched.tagName && errors.tagName}
            label="Tên tag"
          />
        </Stack>

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <LoadingButton fullWidth variant="contained" type="submit" loading={isSubmitting}>
            Cập nhật
          </LoadingButton>
          {/* <Button
            variant="contained"
            onClick={() => {
              console.log(values);
            }}
          >
            CHECK
          </Button> */}
        </Box>
      </Form>
    </FormikProvider>
  );
};
export default CreateLessonTag;
