import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
  Typography
} from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { UrlConfigs } from 'src/utils/UrlConfig';

const ConfirmBankingDialog = () => (
  <Dialog open>
    <DialogTitle variant="h4" sx={{ textAlign: 'center', py: 3 }}>
      Gửi yêu cầu thành công
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ mb: 5 }} fontStyle="italic">
        Vui lòng đợi The Forum xác nhận thông tin thanh toán và nhận khóa học nhé, bạn có thể theo
        dõi trạng thái khóa học đã mua ở Khóa học của tôi
      </DialogContentText>

      <Stack spacing={2}>
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          sx={{ textAlign: 'center', width: '100%' }}
          to={UrlConfigs.MY_LESSON}
          replace
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{ maxWidth: '200px', alignSelf: 'center' }}
            B
          >
            Khóa học của tôi
          </Button>
        </Link>
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          sx={{ textAlign: 'center', width: '100%' }}
          to="/"
          replace
        >
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ maxWidth: '150px', alignSelf: 'center' }}
            B
          >
            Trang chủ
          </Button>
        </Link>
      </Stack>

      <Typography variant="body2" align="left" sx={{ color: 'text.secondary', my: 3 }}>
        Mọi thắc mắc vui lòng tham khảo{' '}
        <Link
          component={RouterLink}
          underline="always"
          sx={{ color: 'text.primary' }}
          to={UrlConfigs.DAT_HANG}
        >
          Chính sách vận chuyển
        </Link>{' '}
        và{' '}
        <Link
          component={RouterLink}
          underline="always"
          sx={{ color: 'text.primary' }}
          to={UrlConfigs.DAT_HANG}
        >
          Chính sách đặt hàng
        </Link>
      </Typography>
    </DialogContent>
  </Dialog>
);
export default ConfirmBankingDialog;
