import {
  CREATE_LESSON,
  CREATE_LESSON_FAILED,
  CREATE_LESSON_SUCCESSFULLY,
  GET_LESSON,
  GET_LESSON_BY_TAG,
  GET_LESSON_ALL_SUCCESSFULLY,
  GET_LESSON_DONE,
  GET_LESSON_DONE_SUCCESFULLY,
  GET_LESSON_FAILED,
  GET_LESSON_SUCCESSFULLY,
  GET_LESSON_SEARCH_SUCCESSFULLY,
  GET_MY_LESSON_SUCCESSFULLY,
  SEEN_LESSON,
  SEEN_LESSON_SUCCESSFULLY,
  SELECT_LESSON,
  UPDATE_LESSON,
  UPDATE_LESSON_FAILED,
  UPDATE_LESSON_SUCCESSFULLY,
  CLEAR_SELECTED_LESSON,
  GET_LESSON_SEO,
  GET_LESSON_BY_TAG_SUCCESSFULLY,
  GET_LESSON_SEO_SUCCESSFULLY
} from './lesson.action';

const initState = {
  data: {
    selected: null,
    done: null,
    list: [],
    myLesson: []
  },
  loading: true,
  errorMessage: ''
};

export function lessonReducer(state = initState, action) {
  switch (action.type) {
    case CREATE_LESSON:
    case GET_LESSON:
    case GET_LESSON_SEO:
    case GET_LESSON_BY_TAG:
    case SEEN_LESSON:
    case GET_LESSON_DONE:
    case UPDATE_LESSON:
      return { ...state, loading: true };
    case SEEN_LESSON_SUCCESSFULLY:
      return { ...state, loading: false };
    case GET_MY_LESSON_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          myLesson: action.payload
        }
      };
    case GET_LESSON_SEARCH_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          search: action.payload
        }
      };
    case GET_LESSON_ALL_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          list: action.payload
        }
      };
    case SELECT_LESSON:
      return {
        ...state,
        data: {
          ...state.data,
          selected: state.data.list.filter((lesson) => lesson._id === action.payload)[0]
        }
      };
    case GET_LESSON_BY_TAG_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          lessonByTag: action.payload
        }
      };
    case CREATE_LESSON_SUCCESSFULLY:
    case GET_LESSON_SUCCESSFULLY:
    case GET_LESSON_SEO_SUCCESSFULLY:
    case UPDATE_LESSON_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        data: { ...state.data, selected: action.payload }
      };
    case GET_LESSON_DONE_SUCCESFULLY:
      return {
        ...state,
        loading: false,
        data: { ...state.data, done: action.payload }
      };
    case CREATE_LESSON_FAILED:
    case GET_LESSON_FAILED:
    case UPDATE_LESSON_FAILED:
      return { ...state, loading: false, errorMessage: action.errorMessage };

    case CLEAR_SELECTED_LESSON:
      return { ...state, data: { ...state.data, selected: null }, loading: true };
    default:
      return state;
  }
}
