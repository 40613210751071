import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// material
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { MHidden } from 'src/components/@material-extend';
import { BottomNavigateContact } from 'src/components/bottomnavigate/BottomNavigateContact';
import { DesktopContact } from 'src/components/bottomnavigate/DesktopContact';
import SupportDialog from 'src/components/Supports/SupportDialog';
import AdsHeader from './AdsHeader';
import NavbarSecondary from './NavbarSecondary';
import Sidebar from './Sidebar';

// ----------------------------------------------------------------------

const ADS_HEIGHT = 80;

const HeaderStyle = styled('header')(({ theme, height }) => ({
  top: height,
  left: 0,
  lineHeight: 0,
  background: '#f1f1f15c',
  zIndex: 100,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(1, 5, 2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1, 7, 2)
  }
}));

// ----------------------------------------------------------------------

export default function HeaderLayout({ outlet = true }) {
  const [open, setOpen] = useState(false);
  const [closeAds, setCloseAds] = useState(true);

  return (
    <>
      {/* <MHidden width="mdDown">
        <Header />
      </MHidden> */}

      {/* <AdsHeader closeAds={closeAds} setCloseAds={setCloseAds} /> */}

      <NavbarSecondary
        closeAds={closeAds}
        setCloseAds={setCloseAds}
        onOpenSidebar={() => setOpen(true)}
      />

      <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      {outlet && (
        <div style={{ minHeight: '100vh' }}>
          <Outlet />
        </div>
      )}

      <MHidden width="xlUp">
        <BottomNavigateContact />
      </MHidden>

      <MHidden width="xlDown">
        <DesktopContact />
      </MHidden>
    </>
  );
}

HeaderLayout.propTypes = {
  outlet: PropTypes.bool
};
