// material
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import SupportSectionForm from 'src/components/Supports/SupportSectionForm';
import { SetContentDialogSupport } from 'src/store/dialogs/dialog.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
// ----------------------------------------------------------------------

export default function SupportSection() {
  const [sent, setSent] = useState(false);

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.data.current)
      dispatch(
        SetContentDialogSupport({
          name: `${user.data.current.firstName} ${user.data.current.lastName}`,
          phone: user.data.current.phone
        })
      );
  }, [user]);

  if (sent) return <Navigate to={UrlConfigs.THANKYOU_SUPPORT} />;

  return (
    <div
      style={{
        backgroundColor: '#fffbf7',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative'
      }}
    >
      <div
        style={{
          alignSelf: 'center',
          color: 'white',
          backgroundColor: 'rgb(57 57 57)',
          opacity: 0.7,
          position: 'absolute',
          height: '100%',
          width: '100%'
        }}
      />

      <Container maxWidth="sm" sx={{ pb: 5, pt: 5 }}>
        <SupportSectionForm sx={{ color: 'white' }} sent={sent} setSent={setSent} />
      </Container>
    </div>
  );
}
