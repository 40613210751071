import { defer } from 'rxjs';
import http from '../network/http';

export function signInByToken({ token, firstName, lastName }) {
  return defer(() => http.post('/v1/auth/sign-in-by-token', { token, firstName, lastName }));
}

export function signUp(body) {
  return defer(() => http.post('/v1/auth/sign-up', body));
}
