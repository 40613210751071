import { defer } from 'rxjs';
import http from '../network/http';
/**
 *
 * @param {
 *  lessonId,
 *  lessonVideoId
 * } lessonDone
 */
export function seenLessonVideo(request) {
  return defer(() => http.post('/v1/lesson-done', request));
}

/**
 *
 * @param {
 *  lessonId
 * }
 */
export function getLessonDone(request) {
  return defer(() => http.get(`/v1/lesson-done/${request}/user`));
}
