import {
  Accordion,
  AccordionDetails,
  Avatar,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { styled } from '@material-ui/styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LeftContainer = styled(Container)(() => ({
  minHeight: '100vh'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
  minHeight: '36px',
  background: '#f8f8f8',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between'
  }
}));

const leftContentConfig = [
  {
    title: 'Cơ bản',
    children: [
      { key: 'basic', title: 'Thông tin cơ bản', description: 'Mô tả, mục tiêu, yêu cầu' },
      { key: 'video', title: 'Video khóa học', description: 'Danh sách chương và video từng phần' },
      { key: 'intro', title: 'Video giới thiệu', description: 'Danh sách video được cho phép xem' },
      { key: 'tags', title: 'Tags khóa học', description: 'Thêm tag cho khóa học' }
    ]
  }
];

const LeftDetailLesson = ({ onChange, activatedTab }) => {
  const [selected, setSelected] = useState(activatedTab);

  const handleSelected = (key) => {
    onChange(key);
    setSelected(key);
  };

  const getActiveStyle = (key) =>
    key === selected ? { style: { backgroundColor: '#f3f3f3' } } : null;

  return (
    <LeftContainer>
      {leftContentConfig.map((config, index) => (
        <Accordion key={index} square={false} defaultExpanded disableGutters>
          <AccordionSummary>
            <Typography>{config.title}</Typography>
            <Typography variant="body2" fontStyle="italic">
              {`${config.children.length} Mục`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List>
              {config.children.map((v, index) => (
                <ListItemButton
                  key={v.key}
                  {...getActiveStyle(v.key)}
                  onClick={() => handleSelected(v.key)}
                >
                  <ListItem disableGutters key={v.title + index}>
                    <ListItemAvatar>
                      <Avatar variant="rounded">
                        <PlayCircleOutlineIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={v.title} secondary={v.description} />
                  </ListItem>
                </ListItemButton>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </LeftContainer>
  );
};

LeftDetailLesson.propTypes = {
  onChange: PropTypes.func.isRequired,
  activatedTab: PropTypes.string
};

export default LeftDetailLesson;
