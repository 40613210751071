import { Box, Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MotionContainer } from 'src/components/animate';
import { transitionValues } from 'src/components/animate/MotionContainer';

const useReadmoreStyles = makeStyles({
  hidden: (props) => ({
    display: '-webkit-box',
    WebkitLineClamp: props.maxLine,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical'
  })
});
export function ReadMore({ children, showButton, maxLine, ...props }) {
  const classes = useReadmoreStyles({ maxLine });
  const [isHidden, setIsHidden] = useState(true);
  return (
    <>
      <Box
        className={isHidden ? classes.hidden : null}
        sx={{
          maskImage: isHidden ? 'linear-gradient(#fff,#fff,rgba(255,255,255,0))' : 'unset'
        }}
      >
        {children}
      </Box>
      {showButton && (
        <MotionContainer initial="initial" open={isHidden}>
          <motion.div
            variants={{
              animate: {
                scale: ['0.98', '0.98', '1'],
                originX: 0,
                transition: transitionValues
              }
            }}
          >
            <Button
              size="small"
              variant="outlined"
              {...props}
              onClick={() => setIsHidden(!isHidden)}
            >
              {isHidden ? `Xem thêm ︾` : `Thu gọn ︽`}
            </Button>
          </motion.div>
        </MotionContainer>
      )}
    </>
  );
}
