import React, { useState, useEffect } from 'react';
import { LoginForm } from 'src/components/authentication/login';
import { useDispatch, useSelector } from 'react-redux';
import { HandleLoginPopup } from 'src/store/auth/signin/signin.action';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Alert
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import AuthSocial from 'src/components/authentication/AuthSocial';
import { ForgetPassword } from 'src/components/authentication/forgetpassword';

export const LoginPopup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openLoginPopup = useSelector((state) => state.auth.openLoginPopup);
  const auth = useSelector((state) => state.auth);
  const [error, setError] = useState();
  const [loginForm, setLoginForm] = useState(true);

  useEffect(() => {
    if (auth.errorMessage) {
      setError(auth.errorMessage.code);
    }
    if (auth.isAuthorized && openLoginPopup) {
      dispatch(HandleLoginPopup());
    }
  }, [auth]);

  return (
    <>
      {/* <Button
        variant="contained"
        sx={{ zIndex: 10000 }}
        onClick={() => {
          dispatch(HandleLoginPopup());
        }}
      >
        TEST POPUP
      </Button> */}
      <Dialog
        onClose={() => dispatch(HandleLoginPopup())}
        open={!auth.isAuthorized && openLoginPopup}
        fullWidth
      >
        <DialogTitle variant="h4" sx={{ textAlign: 'center', py: 3 }}>
          Đăng nhập để tiếp tục
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 5 }}>
            Nếu chưa có tài khoản, đăng ký bên dưới nhé!
          </DialogContentText>
          <AuthSocial popup />
          {error && (
            <Alert severity="error" sx={{ mb: 5 }}>
              {error}
            </Alert>
          )}
          {loginForm ? (
            <LoginForm error={error} setError={setError} setLoginForm={setLoginForm} popup />
          ) : (
            <ForgetPassword error={error} setLoginForm={setLoginForm} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(HandleLoginPopup());
              navigate('/register');
            }}
          >
            Đăng ký tài khoản
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
