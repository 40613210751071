export const CHECKOUT_PAYMENT = 'CHECKOUT_PAYMENT';
export const CHECKOUT_PAYMENT_FAILED = 'CHECKOUT_PAYMENT_FAILED';
export const CHECKOUT_PAYMENT_SUCCESSFULLY = 'CHECKOUT_PAYMENT_SUCCESSFULLY';
export const VERIFY_PAYMENT = 'VERIFY_PAYMENT';
export const VERIFY_PAYMENT_FAILED = 'VERIFY_PAYMENT_FAILED';
export const VERIFY_PAYMENT_SUCCESSFULLY = 'VERIFY_PAYMENT_SUCCESSFULLY';

export const UNLOCK_PAYMENT = 'UNLOCK_PAYMENT';
export const UNLOCK_PAYMENT_FAILED = 'UNLOCK_PAYMENT_FAILED';
export const UNLOCK_PAYMENT_SUCCESSFULLY = 'UNLOCK_PAYMENT_SUCCESSFULLY';

export const VERIFY_LESSON = 'VERIFY_LESSON';
export const VERIFY_LESSON_FAILED = 'VERIFY_LESSON_FAILED';
export const VERIFY_LESSON_SUCCESSFULLY = 'VERIFY_LESSON_SUCCESSFULLY';

export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';

export const FIND_EMAIL = 'FIND_EMAIL';
export const FIND_EMAIL_SUCCESFULLY = 'FIND_EMAIL_SUCCESFULLY';
export const FIND_EMAIL_FAILED = 'FIND_EMAIL_FAILED';

export const FIND_PAYMENT = 'FIND_PAYMENT';
export const FIND_PAYMENT_SUCCESSFULLY = 'FIND_PAYMENT_SUCCESSFULLY';

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const UPDATE_PAYMENT_SUCCESSFULLY = 'UPDATE_PAYMENT_SUCCESSFULLY';

export function CheckoutPayment(paymentInfo) {
  return {
    type: CHECKOUT_PAYMENT,
    payload: paymentInfo
  };
}

export function CheckoutPaymentSuccessfully(paid) {
  return {
    type: CHECKOUT_PAYMENT_SUCCESSFULLY,
    payload: paid
  };
}

export function CheckoutPaymentFailed(errMsg) {
  return {
    type: CHECKOUT_PAYMENT_FAILED,
    errorMessage: errMsg
  };
}

export function VerifyPayment(queryParam, success) {
  return {
    type: VERIFY_PAYMENT,
    payload: queryParam,
    status: success
  };
}
export function VerifyPaymentSuccessfully(response) {
  return {
    type: VERIFY_PAYMENT_SUCCESSFULLY,
    payload: response
  };
}

export function VerifyPaymentFailed(errMsg) {
  return {
    type: VERIFY_PAYMENT_FAILED,
    errorMessage: errMsg
  };
}

export function UnlockPayment(info) {
  return {
    type: UNLOCK_PAYMENT,
    payload: info
  };
}
export function UnlockPaymentSuccessfully(response) {
  return {
    type: UNLOCK_PAYMENT_SUCCESSFULLY,
    payload: response
  };
}

export function UnlockPaymentFailed(errMsg) {
  return {
    type: UNLOCK_PAYMENT_FAILED,
    errorMessage: errMsg
  };
}

export function VerifyLesson(lessonID) {
  return {
    type: VERIFY_LESSON,
    payload: lessonID
  };
}
export function VerifyLessonSuccessfully(response) {
  return {
    type: VERIFY_LESSON_SUCCESSFULLY,
    payload: response
  };
}

export function VerifyLessonFailed(errMsg) {
  return {
    type: VERIFY_LESSON_FAILED,
    errorMessage: errMsg
  };
}

export function ClearPayment() {
  return {
    type: CLEAR_PAYMENT
  };
}

export function FindEmail(searchTerm) {
  return {
    type: FIND_EMAIL,
    payload: searchTerm
  };
}

export function FindEmailsSuccessfully(data) {
  return {
    type: FIND_EMAIL_SUCCESFULLY,
    payload: data
  };
}

export function FindEmailsFailed(data) {
  return {
    type: FIND_EMAIL_FAILED,
    payload: data
  };
}

export function FindPayment(email) {
  return {
    type: FIND_PAYMENT,
    payload: email
  };
}

export function FindPaymentSuccessfully(data) {
  return {
    type: FIND_PAYMENT_SUCCESSFULLY,
    payload: data
  };
}

export function UpdatePayment(payload) {
  return {
    type: UPDATE_PAYMENT,
    payload: payload
  };
}

export function UpdatePaymentSuccessfully(data) {
  return {
    type: UPDATE_PAYMENT_SUCCESSFULLY,
    payload: data
  };
}
