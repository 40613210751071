/* eslint-disable jsx-a11y/label-has-associated-control */
// material
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Link,
  FormControl
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { uploadFile } from 'src/apis/firebase.api';
import { LEVEL, SELL_LEVEL } from 'src/constants/constant';
import { CreateLesson, UpdateLesson } from 'src/store/lesson/lesson.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import * as Yup from 'yup';
import { getParentLessons } from '../../apis/lesson.api';
import { GetCategories } from '../../store/category/category.action';
import FileUpload from '../@material-extend/FileUpload';
import './CKEditor.css';

// ----------------------------------------------------------------------

const CreateLessonForm = () => {
  const levelOptions = LEVEL;
  const sellLevelOption = SELL_LEVEL;

  const categoryState = useSelector((state) => state.categories);
  const lessonState = useSelector((state) => state.lessons);

  const [parents, setParents] = useState(null);
  const [createdDocument, setCreatedDocument] = useState(1);
  const [createdAttribute, setCreatedAttribute] = useState(1);
  const [createdBenefit, setCreatedBenefit] = useState(1);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [attribute, setAttribute] = useState({ icon: '', value: '' });
  const [benefit, setBenefit] = useState({ header: '', content: '' });
  const [url, setUrl] = useState(lessonState.data.selected?.thumbnail || '');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const LessonSchema = Yup.object().shape({
    courseName: Yup.string().required('Vui lòng điền tên khoá học'),
    subtitle: Yup.string(),
    category: Yup.string().required('Vui lòng chọn loại khóa học'),
    level: Yup.string().required('Vui lòng chọn thông tin'),
    parent: Yup.string(),
    documents: Yup.array(),
    thumbnail: Yup.string(),
    introduction: Yup.string().required('Vui lòng điền thông tin'),
    price: Yup.number(),
    sellLevel: Yup.number().min(0).default(sellLevelOption[0][1])
  });

  const formik = useFormik({
    initialValues: {
      courseName: lessonState.data.selected ? lessonState.data.selected?.name : '',
      subtitle: lessonState.data.selected ? lessonState.data.selected?.subtitle : '',
      introduction: lessonState.data.selected ? lessonState.data.selected.introduction : '',
      category: lessonState.data.selected ? lessonState.data.selected.category?._id : '',
      parent: lessonState.data.selected ? lessonState.data.selected.parent?._id : '',
      level: lessonState.data.selected ? lessonState.data.selected.level : '',
      documents: [],
      attributes: [],
      benefits: [],
      price: lessonState.data.selected ? lessonState.data.selected.price : 0,
      sellLevel: lessonState.data.selected
        ? lessonState.data.selected.sellLevel
        : sellLevelOption[0][1],
      thumbnail: lessonState.data.selected?.thumbnail,
      description: lessonState.data.selected
        ? lessonState.data.selected?.description
        : 'BẠN SẼ NHẬN ĐƯỢC GÌ?',
      outcome: lessonState.data.selected
        ? lessonState.data.selected?.outcome
        : 'KHÓA HỌC NÀY DÀNH CHO AI',
      requirement: lessonState.data.selected
        ? lessonState.data.selected?.requirement
        : 'MÔ TẢ KHÓA HỌC'
    },
    validationSchema: LessonSchema,
    enableReinitialize: true,
    onSubmit: () => {
      const {
        courseName,
        subtitle,
        category,
        level,
        documents,
        attributes,
        benefits,
        price,
        sellLevel,
        introduction,
        parent,
        description,
        outcome,
        requirement
      } = values;

      const action = lessonState.data.selected
        ? UpdateLesson(lessonState.data.selected._id, {
            name: courseName,
            subtitle: subtitle,
            description: description,
            outcome: outcome,
            requirement: requirement,
            categoryId: category,
            documents: documents.length > 0 ? documents : null,
            attributes: attributes.length > 0 ? attributes : null,
            benefits: benefits.length > 0 ? benefits : null,
            thumbnail: url,
            introduction,
            parent: parent === '' ? null : parent,
            price,
            sellLevel,
            level: level
          })
        : CreateLesson({
            name: courseName,
            subtitle: subtitle,
            description: description,
            outcome: outcome,
            requirement: requirement,
            categoryId: category,
            introduction,
            documents,
            thumbnail: url,
            sellLevel,
            level: level
          });
      dispatch(action);
      resetAll();
    }
  });

  const resetAll = () => {
    resetForm();
    setBenefit({ header: '', content: '' });
    setCreatedBenefit(1);
    setAttribute({ icon: '', value: '' });
    setCreatedAttribute(1);
    setCreatedDocument(1);
  };

  const openWidget = () => {
    // eslint-disable-next-line no-undef
    const CloudinaryWidget = cloudinary.openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_PRESET
      },
      (error, res) => {
        if (!error && res && res.event === 'success') {
          console.log('Done! Here is the image info: ', res.info);
          setUrl(res.info.url);
        }
      }
    );
  };

  const handleFileChange = (name, files) => {
    if (files.length > 0) {
      setUploadLoading(true);
      const file = files[0];
      uploadFile(file, 'documents').then((url) => {
        switch (name) {
          case 'documents':
            setFieldValue(name, [
              ...values.documents,
              { name: file.name.substr(0, file.name.lastIndexOf('.')), url }
            ]);
            break;
          default:
            setFieldValue(name, url);
            break;
        }
        setUploadLoading(false);
      });
    } else {
      window.alert('Chưa chọn file');
    }
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    handleChange,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    resetForm,
    dirty
  } = formik;
  useEffect(() => {
    dispatch(GetCategories());
  }, []);

  useEffect(() => {
    const subscription = getParentLessons().subscribe((res) => {
      setParents(res.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (lessonState.data.selected) {
      setSubmitting(false);
      navigate(`${UrlConfigs.CREATE_LESSON}/${lessonState.data.selected?.seoTitle}`);
      setUrl(lessonState.data.selected.thumbnail);
    }
  }, [lessonState.data]);

  const showOnEdit = () => {
    if (lessonState.data.selected) {
      return (
        <>
          <Select
            displayEmpty
            onChange={handleChange}
            value={values.parent}
            {...getFieldProps('parent')}
            defaultValue=""
            error={Boolean(touched.parent && errors.parent)}
            label="Chọn khóa học phụ thuộc"
          >
            <MenuItem value="">Chọn khóa học</MenuItem>
            {lessonState.data.selected.parent && (
              <MenuItem value={lessonState.data.selected.parent?._id} disabled>
                {lessonState.data.selected.parent?.name}
              </MenuItem>
            )}
            {parents &&
              parents.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          <FormControl>
            <InputLabel htmlFor="outlined-adornment-vnd">Giá khóa học</InputLabel>
            <OutlinedInput
              id="outlined-adornment-vnd"
              fullWidth
              autoComplete="price"
              type="number"
              prefix="VND"
              endAdornment={<InputAdornment position="end">VND</InputAdornment>}
              {...getFieldProps('price')}
              error={Boolean(touched.price && errors.price)}
              helpertext={touched.price && errors.price}
            />
          </FormControl>

          <FileUpload
            label="Ảnh bìa"
            defaultValue={url}
            onFileChange={(files) => handleFileChange('thumbnail', files)}
            multiple={false}
            accept="image/png, image/jpeg"
            disabled={uploadLoading}
            hideInput
          />
          <Button
            style={{ marginTop: 0, width: '10%' }}
            variant="contained"
            onClick={() => {
              openWidget();
            }}
          >
            Tải ảnh
          </Button>

          <div style={{ position: 'relative' }}>
            <label htmlFor="documents">Tài liệu</label>
            {lessonState.data.selected?.documents?.length > 0 && (
              <List component="nav" style={{ marginBottom: 5 }}>
                {lessonState.data.selected.documents.map((document, index) => (
                  <ListItem
                    button
                    component="a"
                    href={document.url}
                    target="__blank"
                    key={`documents_file_${index}`}
                  >
                    <ListItemText
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      primary={document.name}
                      prefix="..."
                    >
                      {document.name}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            )}
            {[...Array(createdDocument).keys()].map((val) => (
              <input
                disabled={uploadLoading}
                key={`documents_${val}`}
                style={{ width: '100%', marginBottom: 5 }}
                id="documents"
                name="documents"
                type="file"
                accept=".pdf,.doc,.docx,.xml,application/msword ,.ppt ,.pptx"
                onChange={(e) => handleFileChange('documents', e.target.files)}
              />
            ))}
            <AddIcon
              style={{ position: 'absolute', bottom: 0, left: '-25px' }}
              onClick={() => {
                setCreatedDocument(createdDocument + 1);
              }}
            />
          </div>

          <div style={{ position: 'relative' }}>
            <label htmlFor="attributes">Thuộc tính</label>
            {lessonState.data.selected?.attributes?.length > 0 && (
              <List component="nav" style={{ marginBottom: 5 }}>
                {lessonState.data.selected.attributes.map((attribute, index) => (
                  <ListItem button key={`attributes_db_${index}`}>
                    <ListItemText
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      primary={
                        <>
                          <span style={{ marginRight: 10 }}>
                            <Icon>{attribute.icon}</Icon>
                          </span>
                          {attribute.value}
                        </>
                      }
                      prefix="..."
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {[...Array(createdAttribute).keys()].map((val) => (
              <div
                key={`attributes_${val}`}
                style={{ display: 'flex', flex: 1 }}
                onBlur={() => {
                  if (attribute.icon && attribute.value) {
                    setFieldValue('attributes', [
                      ...new Map(
                        [...values.attributes, attribute].map((item) => [item.value, item])
                      ).values()
                    ]);
                    setAttribute({ icon: '', value: '' });
                  }
                }}
              >
                {/* icon name: https://www.angularjswiki.com/angular/angular-material-icons-list-mat-icon-list/ */}
                <TextField
                  style={{ flex: 0.5, marginBottom: 5 }}
                  id="attribute_icon"
                  name="attribute_icon"
                  placeholder="Tên icon"
                  type="text"
                  onChange={(e) => setAttribute({ ...attribute, icon: e.target.value })}
                />
                <TextField
                  style={{ flex: 0.5, marginBottom: 5 }}
                  id="attribute_value"
                  name="attribute_value"
                  placeholder="Thông tin"
                  type="text"
                  onChange={(e) => setAttribute({ ...attribute, value: e.target.value })}
                />
              </div>
            ))}
            <AddIcon
              style={{ position: 'absolute', bottom: 0, left: '-25px' }}
              onClick={() => {
                setCreatedAttribute(createdAttribute + 1);
              }}
            />
          </div>

          <div style={{ position: 'relative' }}>
            <label htmlFor="benefits">Quyền lợi</label>
            {lessonState.data.selected?.benefits?.length > 0 && (
              <List component="nav" style={{ marginBottom: 5 }}>
                {lessonState.data.selected.benefits?.map((benefit, index) => (
                  <ListItem button key={`benefits_db_${index}`}>
                    <ListItemText
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      primary={`${benefit.header}: ${benefit.content}`}
                      prefix="..."
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {[...Array(createdBenefit).keys()].map((val) => (
              <div
                key={`benefits_${val}`}
                style={{ display: 'flex', flex: 1 }}
                onBlur={() => {
                  if (benefit.header && benefit.content) {
                    setFieldValue('benefits', [
                      ...new Map(
                        [...values.benefits, benefit].map((item) => [item.header, item])
                      ).values()
                    ]);
                    setBenefit({ header: '', content: '' });
                  }
                }}
              >
                <TextField
                  style={{ flex: 0.5, marginBottom: 5 }}
                  id="benefit_header"
                  name="benefit_header"
                  placeholder="Tiêu đề"
                  type="text"
                  onChange={(e) => setBenefit({ ...benefit, header: e.target.value })}
                />
                <TextField
                  style={{ flex: 0.5, marginBottom: 5 }}
                  id="benefit_content"
                  name="benefit_content"
                  placeholder="Nội dung"
                  type="text"
                  onChange={(e) => setBenefit({ ...benefit, content: e.target.value })}
                />
              </div>
            ))}
            <AddIcon
              style={{ position: 'absolute', bottom: 0, left: '-25px' }}
              onClick={() => {
                setCreatedBenefit(createdBenefit + 1);
              }}
            />
          </div>
        </>
      );
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Stack spacing={3}>
          {lessonState.data.selected && (
            <Link
              underline="none"
              variant="subtitle2"
              component={RouterLink}
              to={`/khoa-hoc/${lessonState.data.selected.seoTitle}`}
            >
              Xem khóa học
            </Link>
          )}
          <TextField
            fullWidth
            autoComplete="course-name"
            type="text"
            {...getFieldProps('courseName')}
            error={Boolean(touched.courseName && errors.courseName)}
            helperText={touched.courseName && errors.courseName}
            label="Tên khoá học"
            placeholder="Tên Khóa học"
          />
          <Box>
            <InputLabel>Tên phụ của khóa học (VD: Đã bao gồm Reading,...)</InputLabel>
            <CKEditor
              editor={ClassicEditor}
              config={{}}
              data={values.subtitle || ''}
              onChange={(event, editor) => {
                setFieldValue('subtitle', editor.getData());
              }}
            />
          </Box>
          <TextField
            select
            label="Loại khóa học"
            labelId="select-category"
            onChange={handleChange}
            value={values.category}
            {...getFieldProps('category')}
            defaultValue=""
            error={Boolean(touched.category && errors.category)}
          >
            <MenuItem value="" disabled>
              Chọn khóa học
            </MenuItem>
            {categoryState.data &&
              categoryState.data.map((category) => (
                <MenuItem key={`${category._id}`} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
          </TextField>

          {touched.category && errors.category ? (
            <FormHelperText style={{ color: 'red', marginTop: 5, marginLeft: 15 }}>
              {errors.category}
            </FormHelperText>
          ) : null}
          <TextField
            select
            onChange={handleChange}
            label="Chọn mức độ"
            value={values.level}
            {...getFieldProps('level')}
            defaultValue=""
            error={Boolean(touched.level && errors.level)}
          >
            <MenuItem value="" disabled>
              Chọn mức độ
            </MenuItem>
            {levelOptions.map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            autoComplete="course-sell-level"
            {...getFieldProps('sellLevel')}
            error={Boolean(touched.sellLevel && errors.sellLevel)}
            helperText={touched.sellLevel && errors.sellLevel}
            label="Khu vực hiển thị"
            defaultValue=""
          >
            <MenuItem value="" disabled>
              Chọn khu vực hiển thị
            </MenuItem>
            {sellLevelOption.map((sellLevel) => (
              <MenuItem key={`$sell-level-${sellLevel.level}`} value={sellLevel.level}>
                {sellLevel.value}
              </MenuItem>
            ))}
          </TextField>

          <Box>
            <InputLabel>BẠN SẼ NHẬN ĐƯỢC GÌ?</InputLabel>
            <CKEditor
              editor={ClassicEditor}
              config={{}}
              data={values.description}
              onChange={(event, editor) => {
                setFieldValue('description', editor.getData());
              }}
            />
          </Box>
          <Box>
            <InputLabel>KHÓA HỌC NÀY DÀNH CHO AI</InputLabel>
            <CKEditor
              editor={ClassicEditor}
              data={values.requirement}
              onChange={(event, editor) => {
                setFieldValue('requirement', editor.getData());
              }}
            />
          </Box>
          <Box>
            <InputLabel>MÔ TẢ KHÓA HỌC</InputLabel>
            <CKEditor
              editor={ClassicEditor}
              data={values.outcome}
              onChange={(event, editor) => {
                setFieldValue('outcome', editor.getData());
              }}
            />
          </Box>

          <TextField
            fullWidth
            multiline
            minRows={4}
            autoComplete="course-introduction"
            type="text"
            {...getFieldProps('introduction')}
            error={Boolean(touched.introduction && errors.introduction)}
            helperText={touched.introduction && errors.introduction}
            label="Giới thiệu khoá học (hiện ở trang chủ)"
          />

          {showOnEdit()}
        </Stack>

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            loading={uploadLoading || isSubmitting}
          >
            {lessonState.data.selected ? 'Cập nhật' : 'Tạo'}
          </LoadingButton>
          {/* <Button
            variant="contained"
            onClick={() => {
              console.log(values);
            }}
          >
            CHECK
          </Button> */}
        </Box>
      </Form>
    </FormikProvider>
  );
};
export default CreateLessonForm;
