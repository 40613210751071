import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  InputAdornment,
  Link,
  Rating,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { DISCOUNT } from 'src/constants/constant';
import { GetLesson } from 'src/store/lesson/lesson.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { formatCurrency } from 'src/utils/formatcurrency';
// ----------------------------------------------------------------------

const SearchbarStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 300,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      width: 500,
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12
      }
    },
    '& .MuiAutocomplete-inputRoot': {
      '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
      }
    },
    '& .MuiAutocomplete-option': {
      '&:not(:last-child)': {
        borderBottom: `solid 1px ${theme.palette.divider}`
      }
    }
  },

  '& .MuiAutocomplete-popper': {
    '& .MuiPaper-root': {
      overflowX: 'hidden'
    }
  }
}));

export const ThumbnailStyled = styled('img')({
  marginRight: 3,
  objectFit: 'contain',
  width: '13em'
});

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [homeLesson, setHomeLesson] = useState([]);
  const [value, setValue] = useState('');

  const lessonState = useSelector((state) => state.lessons);

  const dispatch = useDispatch();

  useEffect(() => {
    onOpenHandler();
  }, [lessonState.data.search]);

  const onOpenHandler = () => {
    if (Array.isArray(lessonState.data.search)) {
      setHomeLesson(lessonState.data.search);
      return;
    }
    if (!lessonState.data.list.level1) return;
    let homeCourse = [];
    [1, 2, 3].map(
      (level) => (homeCourse = [...homeCourse, ...lessonState.data.list[`level${level}`]])
    );
    setHomeLesson(homeCourse);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeHandler = (event, values) => {
    if (!values) return;
    setValue(values);
    console.log('SEARCH, ', values);
    dispatch(
      GetLesson(null, {
        pageSize: 10,
        pageIndex: 1,
        keyword: values
      })
    );
  };
  const onSearchChange = useCallback(debounce(changeHandler, 600), []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <Stack direction="row">
            {/* <MHidden width="lgDown">
              <Typography
                alignSelf="center"
                align="center"
                variant="h5"
                sx={{ color: 'text.primary', alignSelf: 'center' }}
              >
                Tìm kiếm khoá học <br />
              </Typography>
            </MHidden> */}
            {/* <IconButton onClick={handleOpen}>
              <Icon icon={searchFill} width={20} height={20} />
            </IconButton> */}
            <SearchbarStyle>
              <Autocomplete
                onOpen={onOpenHandler}
                size="medium"
                disableClearable
                clearOnEscape={false}
                popupIcon={null}
                noOptionsText="Đợi xíu, The Forum đang tìm..."
                options={homeLesson}
                autoHighlight
                onInputChange={onSearchChange}
                getOptionLabel={(lesson) =>
                  [lesson.name, lesson.thumbnail, lesson.seoTitle, lesson.price].join('#')
                }
                renderOption={(option) => {
                  if (document.querySelector('.MuiAutocomplete-popper')) {
                    document.querySelector('.MuiAutocomplete-popper').style.display = 'none';
                    setTimeout(() => {
                      if (document.querySelector('.MuiAutocomplete-popper')) {
                        document.querySelector('.MuiAutocomplete-popper').style.width = '500px';
                        document.querySelector('.MuiAutocomplete-popper').style.display = 'block';
                      }
                      window.dispatchEvent(new Event('resize'));
                    }, 200);
                  }
                  const course = option.key.split('#');
                  return (
                    <div style={{ padding: 4 }}>
                      {option['data-option-index'] === 0 && (
                        <Stack>
                          <Typography gutterBottom variant="h5" textAlign="center">
                            Khóa học gợi ý
                          </Typography>
                        </Stack>
                      )}
                      <Stack direction="row" padding={2} alignItems="center">
                        <ThumbnailStyled
                          sx={{ objectFit: 'scale-down', maxWidth: '200px', paddingRight: 2 }}
                          alt={course[0]}
                          src={course[1]}
                        />
                        <Typography gutterBottom flexGrow={1} maxWidth={400}>
                          {course[0]}
                          <Box component="fieldset" borderColor="transparent">
                            <strike> {formatCurrency(course[3])}</strike>{' '}
                            <span
                              style={{
                                color: 'red',
                                fontSize: '1.2rem',
                                fontWeight: 'bold'
                              }}
                            >
                              {formatCurrency(course[3] * (1 - DISCOUNT / 100))}
                            </span>
                            <Rating name="simple-controlled" value={4.5} readOnly precision={0.5} />
                          </Box>
                        </Typography>
                        <Link
                          component={RouterLink}
                          to={`${UrlConfigs.LESSON_DETAIL}/${course[2]}`}
                          underline="none"
                          target="_parent"
                        >
                          <Button
                            sx={{ height: '100%', minWidth: '5vw', mr: 1 }}
                            variant="outlined"
                          >
                            Xem
                          </Button>
                        </Link>
                      </Stack>
                      {option['data-option-index'] === homeLesson.length - 1 && (
                        <Link
                          component={RouterLink}
                          underline="none"
                          to={UrlConfigs.ALL_COURSES}
                          textAlign="center"
                        >
                          <Button
                            sx={{ margin: 3 }}
                            target="_blank"
                            variant="outlined"
                            size="small"
                          >
                            Xem thêm
                          </Button>
                        </Link>
                      )}
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tìm kiếm khóa học..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <Box
                              component={Icon}
                              icon={searchFill}
                              sx={{
                                ml: 1,
                                width: 20,
                                height: 20,
                                color: 'text.disabled'
                              }}
                            />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </SearchbarStyle>
          </Stack>
        )}

        {/* <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          
        </Slide> */}
      </div>
    </ClickAwayListener>
  );
}
