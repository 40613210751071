import {
  Box,
  Container,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { EditOutlined } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { DatePicker } from 'src/components/datePicker/DatePicker';
import { mapStatusCode } from 'src/components/profile/MyLessonCard/MyLessonCard';
import SearchEmail from 'src/components/unlock/SearchEmail';
import { EMAIL_REGEX } from 'src/constants/constant';
import { GetLessonAll } from 'src/store/lesson/lesson.action';
import { FindPayment, UnlockPayment, UpdatePayment } from 'src/store/payment/payment.action';
import { formatCurrency } from 'src/utils/formatcurrency';
import { showError } from 'src/utils/toast';
import * as Yup from 'yup';
import { a11yProps } from '../Course/CoursePreview/CoursePreview.utils';

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  marginInline: 5,
  paddingTop: '70px',
  paddingBottom: theme.spacing(5)
}));

// ---------------------------------------------------------------------
export default function UnlockPaymentPage() {
  const lessonState = useSelector((state) => state.lessons);
  const paymentState = useSelector((state) => state.payment);
  const [date, setDate] = useState(new Date());
  const [searchEmail, setSearchEmail] = useState('');
  const [tab, setTab] = useState(0);
  const [editPayment, setEditPayment] = useState({});

  const dispatch = useDispatch();

  const handleDate = (newValue) => {
    setDate(newValue);
  };
  const handleTab = (event, newValue) => {
    setDate(new Date());
    setTab(newValue);
  };

  const handleEmailFetching = () => {
    setEditPayment({});
    if (EMAIL_REGEX.test(searchEmail)) dispatch(FindPayment(searchEmail));
    else showError('Thao tác quá nhanh. Vui lòng chọn lại email!');
  };

  const handleUpdatePayment = (data) => {
    dispatch(UpdatePayment(data));
  };

  useLayoutEffect(() => {
    dispatch(GetLessonAll());
  }, []);

  const UnlockSchema = Yup.object().shape({
    lessonId: Yup.string().required('Vui lòng chọn khoá học'),
    email: Yup.string().email('Email không hợp lệ').required('Vui lòng nhập email'),
    bankNumber: Yup.string()
      .min(5, 'Mã giao dịch không hợp lệ')
      .optional()
    // .required('Vui lòng nhập mã giao dịch')
  });

  const formik = useFormik({
    initialValues: {
      lessonId: '',
      email: '',
      bankNumber: ''
    },
    validationSchema: UnlockSchema,
    enableReinitialize: true,
    onSubmit: () => {
      console.log({
        lessonId: values.lessonId,
        email: values.email,
        bankNumber: values.bankNumber,
        date: date
      });
      dispatch(
        UnlockPayment({
          expiredAt: date,
          lessonId: values.lessonId,
          email: values.email,
          bankNumber: values.bankNumber
        })
      );
      resetForm();
    }
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    handleChange,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    resetForm,
    dirty
  } = formik;

  const renderFormUnlock = (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Stack spacing={3}>
          <Typography variant="h4">Mở khóa học cho khách hàng</Typography>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            label="Email khách hàng"
            placeholder="Email khách hàng"
          />
          <TextField
            fullWidth
            select
            label="Chọn khóa học cần mở khóa"
            labelId="select-lesson"
            onChange={handleChange}
            value={values.lessonId}
            {...getFieldProps('lessonId')}
            defaultValue=""
            error={Boolean(touched.lessonId && errors.lessonId)}
          >
            <MenuItem value="" disabled>
              Chọn khóa học
            </MenuItem>
            {lessonState.data.list.length &&
              lessonState.data.list.map((lesson) => (
                <MenuItem key={`${lesson._id}`} value={lesson._id}>
                  {lesson.name}
                </MenuItem>
              ))}
          </TextField>

          <DatePicker date={date} handleDate={handleDate} />

          <TextField
            fullWidth
            autoComplete="bankNumber"
            type="text"
            {...getFieldProps('bankNumber')}
            error={Boolean(touched.bankNumber && errors.bankNumber)}
            helperText={touched.bankNumber && errors.bankNumber}
            label="Mã giao dịch khi đối soát báo có, VD: FTxxxxxx"
            placeholder="Mã giao dịch khi đối soát báo có, VD: FTxxxxxx"
          />
          <LoadingButton fullWidth variant="contained" type="submit" loading={isSubmitting}>
            Mở khóa
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );

  const renderUnlockList = (
    <Stack>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Tra cứu
      </Typography>
      <SearchEmail setValue={setSearchEmail} />
      <LoadingButton
        sx={{ my: 2 }}
        fullWidth
        variant="contained"
        onClick={handleEmailFetching}
        loading={paymentState.loading}
      >
        Tìm thông tin
      </LoadingButton>
      {paymentState.findPayment.length > 0 ? (
        paymentState.findPayment.map((payment, index) => {
          return (
            <Stack sx={{ p: 3, my: 3, borderRadius: 3, background: '#e1e1e1' }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <Box>Khóa học:&nbsp;</Box>
                <Box sx={{ my: 2, fontWeight: 700 }}>
                  {lessonState.data.list.find((lesson) => lesson._id === payment.lesson)?.name}
                </Box>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <Box>Giá mua:&nbsp;</Box>
                <Box sx={{ my: 2, fontWeight: 700 }}>{formatCurrency(payment.price)}</Box>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <Box>Ngày thực hiện:&nbsp; </Box>
                <Box sx={{ my: 2, fontWeight: 700 }}>
                  {new Date(payment.create_at).toLocaleDateString()}
                </Box>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <Box>Tình trạng:&nbsp; </Box>
                <Box sx={{ my: 2, fontWeight: 700 }}>{mapStatusCode(payment.status).message}</Box>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <Box>Hết hạn vào:&nbsp; </Box>
                {!editPayment[payment._id] ? (
                  <Box sx={{ my: 2, fontWeight: 700 }}>
                    {payment.expiredAt
                      ? new Date(payment.expiredAt).toLocaleDateString()
                      : 'Không thời hạn'}
                  </Box>
                ) : (
                  <DatePicker date={date} handleDate={handleDate} />
                )}
                <Box sx={{ color: '#D14242', cursor: 'pointer', mx: 1, display: 'flex' }}>
                  <EditOutlined
                    onClick={() => {
                      setDate(new Date());
                      setEditPayment({ [payment._id]: !editPayment[payment._id] });
                    }}
                  />
                  {editPayment[payment._id] && (
                    <Box
                      onClick={() => {
                        handleUpdatePayment({ id: payment._id, expiredAt: date });
                      }}
                    >
                      Cập nhật
                    </Box>
                  )}
                </Box>
              </Stack>
            </Stack>
          );
        })
      ) : (
        <Box>{paymentState.loading ? 'Đang tìm' : 'Chưa có thông tin'}</Box>
      )}
    </Stack>
  );

  return (
    <RootStyle title="The Forum Education | Xác nhận thanh toán">
      <Container maxWidth="lg">
        <Tabs
          value={tab}
          onChange={handleTab}
          aria-label="Thẻ mở khoá"
          visibleScrollbar
          sx={{ marginBottom: 5 }}
        >
          <Tab label="Mở khoá học" {...a11yProps(0)} />
          <Tab label="Danh sách đã mở" {...a11yProps(1)} />
        </Tabs>
        {tab === 0 && renderFormUnlock}
        {tab === 1 && renderUnlockList}
      </Container>
    </RootStyle>
  );
}
