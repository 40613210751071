export const reviews = [
  {
    content:
      'Trung tâm IELTS chất lượng nhất Vũng Tàu, mình là sinh viên Bách Khoa từ thành phố ra đây học và đạt đc 6.5 trong vòng 2 tuần. Một cơ hội để rời xa bận rộn của thành phố chỉ để tập trung học là một quyết định sáng suốt',
    name: 'Long Duong',
    from: 'Google'
  },
  {
    content:
      'Các anh chị giáo viên vừa dễ thương, tận tâm lại có trình độ IELTS cao, nên mình tiến bộ rất nhanh. Cám ơn The Forum Center!',
    name: 'Hạ Đan',
    from: 'Facebook'
  },
  {
    content:
      'Mọi người nên học ở đây thay vì những trung tâm ielts khác mình đã từng học bởi ở đây mọi người sẽ được tiếp cận với một cách học mới đầy thú vị với một timeline rất thoải mái với tất cả mọi người kể cả người bận rộn cùng tiếp xúc với 1 dàn giáo viên chuyên nghiệp nhưng rất vui tính và thoải mái. Một nơi rất tốt để phát  bạn!',
    name: 'Hoàng Quân',
    from: 'Facebook'
  },
  {
    content:
      'Mình là học viên của trung tâm, các thầy cô rất nhiệt tình tình. Theo mình đánh giá đây là nơi học IELTS tốt nhất Vũng Tàu.',
    name: 'Nguyen Pham',
    from: 'Facebook'
  }
];
