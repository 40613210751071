import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, of } from 'rxjs';
import {
  checkoutPayment,
  findPayment,
  unlockPayment,
  updatePayment,
  verifyLesson,
  verifyPayment
} from 'src/apis/payment';
import { getEmails } from 'src/apis/user.api';
import { STATUS_CANCELED, STATUS_PAID } from 'src/constants/constant';
import { showError, showSuccess } from 'src/utils/toast';
import {
  CHECKOUT_PAYMENT,
  CheckoutPaymentFailed,
  CheckoutPaymentSuccessfully,
  FIND_EMAIL,
  FIND_PAYMENT,
  FindEmailsFailed,
  FindEmailsSuccessfully,
  FindPaymentSuccessfully,
  UNLOCK_PAYMENT,
  UPDATE_PAYMENT,
  UnlockPaymentFailed,
  UnlockPaymentSuccessfully,
  UpdatePaymentSuccessfully,
  VERIFY_LESSON,
  VERIFY_PAYMENT,
  VerifyLessonFailed,
  VerifyLessonSuccessfully,
  VerifyPaymentFailed,
  VerifyPaymentSuccessfully
} from './payment.action';

export const checkoutPaymentEpic = (_action$) =>
  _action$.pipe(
    ofType(CHECKOUT_PAYMENT),
    mergeMap((action) =>
      checkoutPayment(action.payload).pipe(
        map((res) => CheckoutPaymentSuccessfully(res.data)),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau, Checkout failed');
          return of(CheckoutPaymentFailed('Checkout failed'));
        })
      )
    )
  );

export const verifyPaymentEpic = (_action$) =>
  _action$.pipe(
    ofType(VERIFY_PAYMENT),
    mergeMap((action) =>
      verifyPayment(action.payload).pipe(
        map((res) => {
          if (res.data.status === STATUS_PAID) showSuccess('Thanh toán thành công');
          else if (res.data.status === STATUS_CANCELED) showError('Thanh toán thất bại');
          return VerifyPaymentSuccessfully(res.data);
        }),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau, Verify failed');
          return of(VerifyPaymentFailed('Verify failed'));
        })
      )
    )
  );

export const unlockPaymentEpic = (_action$) =>
  _action$.pipe(
    ofType(UNLOCK_PAYMENT),
    mergeMap((action) =>
      unlockPayment(action.payload).pipe(
        map((res) => {
          if (res.data.status === 'PAID') showSuccess('Kích hoạt khóa học thành công');
          return UnlockPaymentSuccessfully(res.data);
        }),
        catchError((err) => {
          console.log('err', err);
          showError(`Kích hoạt khóa học thất bại, code: ${err?.message || ''}`);
          return of(UnlockPaymentFailed('Verify failed'));
        })
      )
    )
  );

export const verifyLessonEpic = (_action$) =>
  _action$.pipe(
    ofType(VERIFY_LESSON),
    mergeMap((action) =>
      verifyLesson(action.payload).pipe(
        map((res) => VerifyLessonSuccessfully(res.data)),
        catchError((err) => {
          console.log('err', err);
          return of(VerifyLessonFailed('Verify failed'));
        })
      )
    )
  );

export const findEmailEpic = (_action$) =>
  _action$.pipe(
    ofType(FIND_EMAIL),
    mergeMap((action) =>
      getEmails(action.payload).pipe(
        map((res) => FindEmailsSuccessfully(res.data)),
        catchError((err) => {
          console.log('err', err);
          showError('Vui lòng chọn đúng email');
          return of(FindEmailsFailed('Find Email failed'));
        })
      )
    )
  );

export const findPaymentEpic = (_action$) =>
  _action$.pipe(
    ofType(FIND_PAYMENT),
    mergeMap((action) =>
      findPayment(action.payload).pipe(
        map((res) => FindPaymentSuccessfully(res.data)),
        catchError((err) => {
          console.log('err', err);
        })
      )
    )
  );

export const updatePaymentEpic = (_action$) =>
  _action$.pipe(
    ofType(UPDATE_PAYMENT),
    mergeMap((action) => {
      const { id, ...payload } = action.payload;
      return updatePayment(id, payload).pipe(
        map((res) => {
          showSuccess('Cập nhật thành công');
          return UpdatePaymentSuccessfully(res.data);
        }),
        catchError((err) => {
          console.log('err', err);
          showError('Cập nhật thất bại');
        })
      );
    })
  );
