import { defer } from 'rxjs';
import http from '../network/http';

export function getCurrentUserInfo() {
  return defer(() => http.get('/v1/user/detail'));
}

export function updateCurrentUserInfo({ photo, firstName, lastName, phone }) {
  return defer(() => http.put('/v1/user/detail', { photo, firstName, lastName, phone }));
}

export function getEmails(searchTerm) {
  return defer(() => http.get(`/v1/user/emails?searchTerm=${searchTerm}`));
}
