// ----------------------------------------------------------------------

export default function ControlLabel() {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          flexGrow: 1
        }
      }
    }
  };
}
