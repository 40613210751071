import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootStyle } from '../Course/CoursePreview/CoursePreview';

export function TelephoneRedirect() {
  const { phone } = useParams();

  useEffect(() => {
    document.getElementById('phone')?.click();
  }, []);

  return (
    <RootStyle title="The Forum Education | Gọi điện thoại">
      <a href={`tel://${phone}`} id="phone">
        {' '}
      </a>
    </RootStyle>
  );
}
