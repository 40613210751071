import { Button, Link, Stack, Typography } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { checkPaymentReponse } from '../Payment.utils';

const VerifiedLessonCallback = ({ type, vnpResponseCode, errorCode, resultCode }) => (
  <Stack>
    <Typography variant="h4" alignSelf="center" mb={5}>
      {checkPaymentReponse(type, vnpResponseCode, errorCode, resultCode)
        ? 'The Forum cảm ơn bạn đã mua khóa học, hãy bắt đầu luyện tập ngay!'
        : 'Thanh toán không thành công, vui lòng thử lại sau'}
    </Typography>
    <Link
      underline="none"
      variant="subtitle2"
      component={RouterLink}
      sx={{ textAlign: 'center', width: '100%' }}
      to={`/${UrlConfigs.LESSON_DETAIL}/${localStorage.getItem('last-id-selected')}`}
      replace
    >
      {checkPaymentReponse(type, vnpResponseCode, errorCode, resultCode) && (
        <Button fullWidth size="large" variant="contained" sx={{ maxWidth: '300px', mb: 3 }}>
          Bắt đầu học
        </Button>
      )}
    </Link>
    <Link
      underline="none"
      variant="subtitle2"
      component={RouterLink}
      sx={{ textAlign: 'center', width: '100%' }}
      to={UrlConfigs.MY_LESSON}
      replace
    >
      <Button
        fullWidth
        size="large"
        variant="outlined"
        sx={{ maxWidth: '200px', alignSelf: 'center' }}
        B
      >
        Khóa học của tôi
      </Button>
    </Link>
  </Stack>
);

export default VerifiedLessonCallback;
