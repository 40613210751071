import { ofType } from 'redux-observable';
import { catchError, map, of, switchMap, takeUntil } from 'rxjs';
import { sendCodForm } from 'src/apis/dialog.api';
import {
  SendCodFormFailed,
  SendCodFormSuccesfully,
  SEND_COD_FORM,
  SEND_COD_FORM_FAILED
} from './dialog.action';

export const CodDialogEpic = (_action$, _state$) =>
  _action$.pipe(
    ofType(SEND_COD_FORM),
    switchMap(() =>
      sendCodForm().pipe(
        map((res) => SendCodFormSuccesfully(res.data)),
        takeUntil(_action$.pipe(ofType(SEND_COD_FORM_FAILED))),
        catchError((err) => {
          console.log('err', err);
          return of(SendCodFormFailed(err));
        })
      )
    )
  );
