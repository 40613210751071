import { combineEpics } from 'redux-observable';
import { signInEpic, signInGoogleEpic } from './auth/signin/signin.epic';
import { signOutEpic } from './auth/signout/signout.epic';
import { signUpEpic, signUpGoogleEpic } from './auth/signup/signup.epic';
import { updatePasswordEpic } from './auth/updatepassword/updatepassword.epic';
import { getCategoriesEpic } from './category/category.epic';
import { getCouponEpic, verifyCouponEpic } from './coupon/coupon.epic';
import { CodDialogEpic } from './dialogs/dialog.epic';
import { loadExampleDataEpic } from './examples/example.epic';
import {
  createLessonAssignmentEpic,
  getAllAssignmentsByLessonIdEpic
} from './lesson-assignment/lesson-assignment.epic';
import {
  createLessonTopicEpic,
  getAllTopicsByLessonIdEpic,
  publicVideoEpic
} from './lesson-topic/lesson-topic.epic';
import {
  createLessonEpic,
  getHomeLessonEpic,
  getLessonAllEpic,
  getLessonDoneEpic,
  getLessonEpic,
  getMyLessonEpic,
  seenLessonVideoEpic,
  updateLessonEpic
} from './lesson/lesson.epic';
import {
  checkoutPaymentEpic,
  findEmailEpic,
  findPaymentEpic,
  unlockPaymentEpic,
  updatePaymentEpic,
  verifyLessonEpic,
  verifyPaymentEpic
} from './payment/payment.epic';
import { getUserEpic, updateCurrentUserEpic } from './user/user.epic';

export default combineEpics(
  loadExampleDataEpic,
  signInEpic,
  signInGoogleEpic,
  signOutEpic,
  signUpEpic,
  signUpGoogleEpic,
  updatePasswordEpic,
  getCategoriesEpic,
  createLessonEpic,
  getLessonEpic,
  getLessonAllEpic,
  updateLessonEpic,
  createLessonTopicEpic,
  seenLessonVideoEpic,
  getLessonDoneEpic,
  getUserEpic,
  updateCurrentUserEpic,
  checkoutPaymentEpic,
  verifyPaymentEpic,
  getMyLessonEpic,
  verifyLessonEpic,
  getAllTopicsByLessonIdEpic,
  publicVideoEpic,
  createLessonAssignmentEpic,
  getAllAssignmentsByLessonIdEpic,
  getHomeLessonEpic,
  unlockPaymentEpic,
  CodDialogEpic,
  getCouponEpic,
  verifyCouponEpic,
  findEmailEpic,
  findPaymentEpic,
  updatePaymentEpic
);
