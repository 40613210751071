import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAILED,
  GET_CATEGORIES_SUCCESSFULLY
} from './category.action';

const initialExampleState = {
  data: null,
  errorMessage: '',
  loading: true
};

export function categoryReducer(state = initialExampleState, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, loading: true };
    case GET_CATEGORIES_SUCCESSFULLY:
      return { ...state, loading: false, data: action.payload };
    case GET_CATEGORIES_FAILED:
      return { ...state, loading: false, errorMessage: action.errorMessage };
    default:
      return state;
  }
}
