import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, of } from 'rxjs';
import { getCoupon, verifyCoupon } from 'src/apis/coupon.api';
import { showError, showSuccess } from 'src/utils/toast';
import {
  GetCouponFailed,
  GetCouponSuccessfully,
  GET_COUPON,
  VerifyCouponFailed,
  VerifyCouponSuccessfully,
  VERIFY_COUPON
} from './coupon.action';

export const getCouponEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_COUPON),
    mergeMap((action) =>
      getCoupon(action.payload).pipe(
        map((res) => {
          if (res.data.errorCode) {
            showError(res.data.message);
            return GetCouponFailed(res.data.message);
          }
          showSuccess('Mã giảm thành công');
          return GetCouponSuccessfully(res.data);
        }),
        catchError((err) => {
          console.log('err', err);
          showError('Mã giảm không hợp lệ');
          return of(GetCouponFailed('Mã giảm không hợp lệ'));
        })
      )
    )
  );

export const verifyCouponEpic = (_action$) =>
  _action$.pipe(
    ofType(VERIFY_COUPON),
    mergeMap((action) =>
      verifyCoupon(action.payload).pipe(
        map((res) => {
          showSuccess('Cập nhật mã giảm thành công');
          return VerifyCouponSuccessfully(res.data);
        }),
        catchError((err) => {
          console.log('err', err);
          showError('Cập nhật mã giảm failed');
          return of(VerifyCouponFailed('Mã giảm cập nhật thất bại'));
        })
      )
    )
  );
