import { defer } from 'rxjs';
import http from '../network/http';
/**
 *
 * @param {
 *  name,
 *  description?,
 *  outcome,
 *  requirement,
 *  categoryId,
 *  level?,
 *  introduction?,
 *  sellLevel?,
 * } lesson
 */
export function createLesson(lesson) {
  return defer(() => http.post('/v1/lesson', lesson));
}

export function getLessonById(id) {
  return defer(() => http.get(`/v1/lesson/${id}`));
}

export function getLessonBySeoTitle(id) {
  return defer(() => http.get(`/v1/lesson/seo-title/${id}`));
}

export function getLessonByTag(tagName) {
  return defer(() => http.get(`/v1/lesson/tags?title=${tagName}`));
}

export function getLessonAll(request = {}) {
  const params = Object.keys(request)
    .filter((key) => request[key] !== null && request[key] !== undefined)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  return defer(() => http.get(`/v1/lesson/find?${params}`));
}

export function getMyLesson() {
  return defer(() => http.get(`/v1/payment/current-user`));
}

export function getParentLessons() {
  return defer(() => http.get(`/v1/lesson/parent`));
}

export function getLessonHome() {
  return defer(() => http.get(`/v1/lesson/home`));
}

/**
 *
 * @param {
 *  name?,
 *  description?,
 *  introduction?,
 *  outcome?,
 *  requirement?,
 *  thumbnail?,
 *  documents?,
 *  attributes?,
 *  categoryId?,
 *  level?
 *  sellLevel?,
 *  price?,
 * } lesson
 */
export function updateLesson(id, lesson) {
  return defer(() => http.patch(`/v1/lesson/${id}`, lesson));
}
