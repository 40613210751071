import googleFill from '@iconify/icons-eva/google-fill';
import { Icon } from '@iconify/react';
// material
import { Button, Divider, Stack, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Login, LoginWithGoogle } from 'src/store/auth/signin/signin.action';

// ----------------------------------------------------------------------

export default function AuthSocial({ popup = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  // useEffect(() => {
  //   if (auth.isAuthorized) {
  //     if (!popup) navigate('/', { replace: true });
  //   }
  // }, [auth]);

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          sx={{ width: '50%' }}
          size="large"
          color="inherit"
          variant="outlined"
          onClick={() => {
            dispatch(LoginWithGoogle());
          }}
        >
          <Icon icon={googleFill} color="#DF3E30" height={32} />
        </Button>

        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Icon icon={facebookFill} color="#1877F2" height={24} />
        </Button> */}

        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Icon icon={twitterFill} color="#1C9CEA" height={24} />
        </Button> */}
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
