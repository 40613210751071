import {
  Accordion,
  AccordionDetails,
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { styled } from '@material-ui/core/styles';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
// material
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { filterDocument, filterDocumentName } from 'src/utils/filterdocument';
import { displayTime } from 'src/utils/formatTime';
import { sortVideoTitle, trimTopicTitle, trimVideoTitle } from 'src/utils/trimtitle';
import { a11yProps } from './CoursePreview/CoursePreview.utils';
import { ReadMore } from './CoursePreview/Readmore';
import { CourseSubmitWrapper } from './CourseSubmitWrapper';

export const MAX_VIDEO_EACH_TOPIC = 1000;

// ----------------------------------------------------------------------
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  minHeight: '36px',
  background: '#f8f8f8',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      key={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

export const CourseTabs = ({
  lesson,
  auth,
  changeVideo,
  selectedVideo,
  selectedTopic,
  setSelectedTopic,
  tab,
  setTab,
  paid
}) => {
  let anyDocument = false;

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }} key={`course-about-${selectedVideo}`}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTabs-scroller div': { overflowX: 'auto' }
        }}
      >
        <Tabs value={tab} onChange={handleChange} aria-label="Thẻ học tập" visibleScrollbar>
          <Tab label="Về khóa học" {...a11yProps(0)} />
          <Tab label="Nội dung bài học" {...a11yProps(1)} />
          {paid && auth.isAuthorized && <Tab label="Góc học tập" {...a11yProps(2)} />}
        </Tabs>
      </Box>
      <ReadMore sx={{ m: 3 }} maxLine={8} showButton={tab === 0}>
        <TabPanel value={tab} index={0} key="tab-0">
          <Box>
            <Typography variant="h5" color="primary.main" gutterBottom>
              BẠN SẼ NHẬN ĐƯỢC GÌ?
            </Typography>
          </Box>
          <Box>
            <div>
              <Typography variant="body" gutterBottom>
                {ReactHtmlParser(lesson?.description)}
              </Typography>
            </div>
          </Box>
          <Divider sx={{ my: 5 }} />
          <Box>
            <Typography variant="h5" color="primary.main" gutterBottom>
              KHÓA HỌC NÀY DÀNH CHO AI
            </Typography>
          </Box>
          <Box>
            <div>
              <Typography variant="body" gutterBottom>
                {ReactHtmlParser(lesson?.requirement)}
              </Typography>
            </div>
          </Box>
          <Divider sx={{ my: 5 }} />
          <Box>
            <Typography variant="h5" color="primary.main" gutterBottom>
              QUYỀN LỢI ĐẶC BIỆT
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={3}>
              {lesson?.benefits.map((benefit, idx) => (
                <Grid item xs={12} sm={12} md={4} key={`benefit-${idx}`}>
                  <Typography variant="body" textAlign="center">
                    <Card sx={{ height: '13rem', overflow: 'auto' }}>
                      <CardContent>
                        <Typography variant="h5" component="div">
                          {benefit.header}
                        </Typography>
                        <Typography
                          sx={{ maxHeight: '6rem', overflow: 'auto' }}
                          color="text.secondary"
                        >
                          {ReactHtmlParser(benefit.content)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Divider sx={{ my: 5 }} />
          <Box>
            <Typography variant="h5" color="primary.main" gutterBottom>
              MÔ TẢ KHÓA HỌC
            </Typography>
          </Box>
          <Box>
            <div>
              <Typography variant="body" gutterBottom>
                {ReactHtmlParser(lesson?.outcome)}
              </Typography>
            </div>
          </Box>
        </TabPanel>
      </ReadMore>
      <TabPanel value={tab} index={1} key="tab-1">
        {lesson &&
          lesson.lessonTopics?.map((lesson, at) => (
            <Accordion
              square={false}
              defaultExpanded={at === 0}
              expanded={at === selectedTopic}
              onClick={() => (selectedTopic === at ? setSelectedTopic() : setSelectedTopic(at))}
              disableGutters
              key={`tab-${at}`}
            >
              <AccordionSummary>
                <Typography component="div">{trimTopicTitle(lesson.name)}</Typography>
                <Typography component="div" variant="body2" fontStyle="italic" sx={{ pt: 1 }}>
                  {`${lesson.lessonVideos.length} bài học`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <List>
                  {lesson.lessonVideos.sort(sortVideoTitle).map((video, idx) => (
                    <ListItemButton
                      disabled={!paid && !video.is_public}
                      onClick={(e) => {
                        e.stopPropagation();
                        changeVideo(video, at * MAX_VIDEO_EACH_TOPIC + idx, video.index);
                      }}
                      selected={selectedVideo === at * MAX_VIDEO_EACH_TOPIC + idx}
                      key={`video-${video}-${idx}`}
                    >
                      <ListItem
                        disableGutters
                        // secondaryAction={<BeenhereIcon sx={{ color: 'dimgrey' }} />}
                      >
                        <ListItemAvatar>
                          <Avatar variant="rounded">
                            <PlayCircleOutlineIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <div>
                          <ListItemText
                            primary={trimVideoTitle(video.title || lesson.name)}
                            secondary={displayTime(Math.round(video.duration))}
                          />
                        </div>
                      </ListItem>
                    </ListItemButton>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
      </TabPanel>
      <TabPanel value={tab} index={2} key="tab-2">
        <Typography variant="h5" color="primary.main" gutterBottom>
          Tài liệu của bài học
        </Typography>
        <List sx={{ overflow: 'auto' }}>
          {lesson?.documents?.map((v, idx) =>
            filterDocument(v, selectedTopic, selectedVideo % MAX_VIDEO_EACH_TOPIC)
              ? (anyDocument = true && (
                  <ListItemButton
                    disabled={!auth.isAuthorized}
                    key={`list-item-button-document-${idx}`}
                    onClick={() => {
                      window.open(
                        v.url,
                        '_blank' // <- This is what makes it open in a new window.
                      );
                    }}
                  >
                    <ListItemIcon>
                      <DescriptionOutlined />
                    </ListItemIcon>
                    <ListItemText primary={filterDocumentName(v.name)} />
                  </ListItemButton>
                ))
              : !anyDocument &&
                idx === lesson.documents.length - 1 && (
                  <Typography variant="body" gutterBottom key={`no-doc-${idx}`}>
                    Chưa có tài liệu nào ở đây. Bạn có thể tìm thấy tài liệu ở mỗi video!
                  </Typography>
                )
          )}
        </List>
        <Typography variant="h5" color="primary.main" gutterBottom>
          Ghi chú
        </Typography>
        <Box component="ul">
          {lesson?.lessonTopics[selectedTopic]?.lessonVideos[selectedVideo]?.description
            ? ReactHtmlParser(
                lesson?.lessonTopics[selectedTopic].lessonVideos[selectedVideo].description
              )
            : 'Bài học này không có ghi chú'}
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h5" color="primary.main" gutterBottom>
          Nộp bài của bạn
        </Typography>
        <CourseSubmitWrapper />
      </TabPanel>
    </Box>
  );
};
