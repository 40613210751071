export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESSFULLY = 'SIGN_OUT_SUCCESSFULLY';

export function SignOut() {
  return {
    type: SIGN_OUT
  };
}

export function SignOutSuccessfully() {
  return {
    type: SIGN_OUT_SUCCESSFULLY
  };
}
