import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { SignOut } from 'src/store/auth/signout/signout.action';

const Logout = () => {
  const dispatch = useDispatch();
  dispatch(SignOut());
  return <Navigate to={UrlConfigs.COURSES} />;
};

export default Logout;
