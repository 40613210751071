export const CREATE_LESSON_TOPIC = 'CREATE_LESSON_TOPIC';
export const CREATE_LESSON_TOPIC_SUCCESSFULLY = 'CREATE_LESSON_TOPIC_SUCCESSFULLY';
export const CREATE_LESSON_TOPIC_FAILED = 'CREATE_LESSON_TOPIC_FAILED';

export const GET_ALL_LESSON_TOPIC = 'GET_ALL_LESSON_TOPIC';
export const GET_ALL_LESSON_TOPIC_SUCCESSFULLY = 'GET_ALL_LESSON_TOPIC_SUCCESSFULLY';
export const GET_ALL_LESSON_TOPIC_FAILED = 'GET_ALL_LESSON_TOPIC_FAILED';

export const PUBLIC_VIDEO = 'PUBLIC_VIDEO';
export const PUBLIC_VIDEO_SUCCESSFULLY = 'PUBLIC_VIDEO_SUCCESSFULLY';
export const PUBLIC_VIDEO_FAILED = 'PUBLIC_VIDEO_FAILED';

export function CreateLessonTopic(lessonId, playlistId) {
  return {
    type: CREATE_LESSON_TOPIC,
    payload: {
      lessonId,
      playlistId
    }
  };
}
export function CreateLessonTopicSuccessfully(lesson) {
  return {
    type: CREATE_LESSON_TOPIC_SUCCESSFULLY,
    payload: lesson
  };
}

export function CreateLessonTopicFailed(errMsg) {
  return {
    type: CREATE_LESSON_TOPIC_FAILED,
    errorMessage: errMsg
  };
}

export function GetAllLessonTopic(lessonId, setTopics) {
  return {
    type: GET_ALL_LESSON_TOPIC,
    payload: {
      lessonId,
      setTopics
    }
  };
}
export function GetAllLessonTopicSuccessfully() {
  return {
    type: GET_ALL_LESSON_TOPIC_SUCCESSFULLY
  };
}

export function GetAllLessonTopicFailed(errMsg) {
  return {
    type: GET_ALL_LESSON_TOPIC_FAILED,
    errorMessage: errMsg
  };
}

export function PublicVideo(lessonId, topicId, videoId, setTopics) {
  return {
    type: PUBLIC_VIDEO,
    payload: {
      topicId,
      videoId,
      lessonId,
      setTopics
    }
  };
}

export function PublicVideoFailed(errMsg) {
  return {
    type: PUBLIC_VIDEO_FAILED,
    errorMessage: errMsg
  };
}
