import { Card, Container, Link, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { MHidden } from 'src/components/@material-extend';
import CreateDetailContent from 'src/components/manager/detail/CreateDetailContent';
import LeftDetailLesson from 'src/components/manager/detail/LeftDetailLesson';
import { GetLessonBySeo } from 'src/store/lesson/lesson.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import Page from '../../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '80px',
  width: '100%',
  padding: '10px 0 0 15px',
  lineHeight: 1
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 300,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  margin: theme.spacing(0, 0, 2, 0)
}));

const ContentStyle = styled('div')(() => ({
  display: 'flex'
}));

const CreateLessonDetail = () => {
  const [activatedTab, setActivatedTab] = useState('video');
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetLessonBySeo(id));
  }, []);

  return (
    <RootStyle>
      <Stack direction="row" sx={{ backgroundColor: 'lightsalmon' }}>
        <TitleContainer>
          <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to={UrlConfigs.COURSES}
            sx={{ color: '#f3f3f3', fontSize: '1rem' }}
          >
            Trang chủ
          </Link>
          <Typography variant="h3" sx={{ color: '#f3f3f3', fontSize: '1rem' }}>
            Update Khóa học
          </Typography>
          <Typography variant="span" sx={{ color: '#f3f3f3', fontSize: '0.8rem' }}>
            The Forum
          </Typography>
        </TitleContainer>
      </Stack>

      <ContentStyle>
        <MHidden width="mdDown">
          <SectionStyle>
            <Typography variant="h4" sx={{ px: 5, my: 2 }}>
              Thông tin
            </Typography>

            <LeftDetailLesson onChange={setActivatedTab} activatedTab={activatedTab} />
          </SectionStyle>
        </MHidden>

        <Container maxWidth="xl">
          <Stack sx={{ my: 2 }}>
            <Typography variant="h4" gutterBottom>
              Tạo khoá học
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Hãy điền thông tin vào bên dưới nhé!
            </Typography>
          </Stack>
          <Stack>
            <CreateDetailContent activatedTab={activatedTab} />
          </Stack>
        </Container>
      </ContentStyle>
    </RootStyle>
  );
};

export default CreateLessonDetail;
