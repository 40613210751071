export const GET_COUPON = 'GET_COUPON';
export const GET_COUPON_SUCCESSFULLY = 'GET_COUPON_SUCCESSFULLY';
export const GET_COUPON_FAILED = 'GET_COUPON_FAILED';

export const VERIFY_COUPON = 'VERIFY_COUPON';
export const VERIFY_COUPON_SUCCESSFULLY = 'VERIFY_COUPON_SUCCESSFULLY';
export const VERIFY_COUPON_FAILED = 'VERIFY_COUPON_FAILED';

export function GetCoupon(payload) {
  return {
    type: GET_COUPON,
    payload: payload
  };
}
export function GetCouponSuccessfully(payload) {
  return {
    type: GET_COUPON_SUCCESSFULLY,
    payload: payload
  };
}

export function GetCouponFailed(errMsg) {
  return {
    type: GET_COUPON_FAILED,
    errorMessage: errMsg
  };
}
export function VerifyCoupon(payload) {
  return {
    type: VERIFY_COUPON,
    payload: payload
  };
}
export function VerifyCouponSuccessfully(payload) {
  return {
    type: VERIFY_COUPON_SUCCESSFULLY,
    payload: payload
  };
}

export function VerifyCouponFailed(errMsg) {
  return {
    type: VERIFY_COUPON_FAILED,
    errorMessage: errMsg
  };
}
