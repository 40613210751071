import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hotupdateDisplayname, isSigned } from 'src/apis/firebase.api';
import { LoginSuccessful } from 'src/store/auth/signin/signin.action';
import { HandleUpdatePopup } from 'src/store/auth/signup/signup.action';

function LoadingMessage() {
  return (
    // <Backdrop sx={{ backgroundColor: 'white' }} open>
    //   <CircularProgress color="warning" />
    // </Backdrop>
    <></>
  );
}

function withSplashScreen(WrappedComponent) {
  return (props) => {
    const [loading, setLoading] = useState(true);
    const user = useSelector((state) => state.user);
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
      const subscription = isSigned().subscribe((userFirebase) => {
        setLoading(false);
        // user: when firebase logged in
        // auth.isAuthorized when backend loggedin
        // displayName: null when first signup and not update displayname yet
        // if (userFirebase && !userFirebase.displayName) {
        //   window.alert('Tài khoản chưa có Họ và Tên, nhấn OK để cập nhật');
        //   let displayName = window.prompt('Vui lòng nhập Họ và Tên');
        //   while (!displayName) {
        //     displayName = window.prompt('Vui lòng nhập Họ và Tên');
        //   }
        //   hotupdateDisplayname(displayName)
        //     .then(() => window.location.reload())
        //     .catch((e) => {
        //       window.alert('Đã có lỗi xảy ra. Vui lòng thử lại sau!');
        //     });
        // }
        if (user && !auth.isAuthorized && userFirebase && userFirebase.displayName) {
          dispatch(
            LoginSuccessful({
              token: userFirebase.accessToken,
              userInfo: {
                displayName: userFirebase.displayName,
                email: userFirebase.email,
                photoURL: userFirebase.photoURL
              }
            })
          );
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }, [dispatch, auth, user]);

    useEffect(() => {
      if (user.data.current && !user.data.current.phone && !auth.openUpdatePopup) {
        dispatch(HandleUpdatePopup());
      }
      if (user.data.current && user.data.current.phone && auth.openUpdatePopup) {
        dispatch(HandleUpdatePopup());
      }
    }, [user, auth]);

    return loading ? <LoadingMessage /> : <WrappedComponent {...props} />;
  };
}

export default withSplashScreen;
