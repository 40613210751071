export const CREATE_LESSON_ASSIGNMENT = 'CREATE_LESSON_ASSIGNMENT';
export const CREATE_LESSON_ASSIGNMENT_SUCCESSFULLY = 'CREATE_LESSON_ASSIGNMENT_SUCCESSFULLY';
export const CREATE_LESSON_ASSIGNMENT_FAILED = 'CREATE_LESSON_ASSIGNMENT_FAILED';

export const GET_ALL_LESSON_ASSIGNMENT = 'GET_ALL_LESSON_ASSIGNMENT';
export const GET_ALL_LESSON_ASSIGNMENT_SUCCESSFULLY = 'GET_ALL_LESSON_ASSIGNMENT_SUCCESSFULLY';
export const GET_ALL_LESSON_ASSIGNMENT_FAILED = 'GET_ALL_LESSON_ASSIGNMENT_FAILED';

export function CreateLessonAssignment(lessonAssignment) {
  return {
    type: CREATE_LESSON_ASSIGNMENT,
    payload: lessonAssignment
  };
}
export function CreateLessonAssignmentSuccessfully(lessonAssignment) {
  return {
    type: CREATE_LESSON_ASSIGNMENT_SUCCESSFULLY,
    payload: lessonAssignment
  };
}

export function CreateLessonAssignmentFailed(errMsg) {
  return {
    type: CREATE_LESSON_ASSIGNMENT_FAILED,
    errorMessage: errMsg
  };
}

export function GetAllLessonAssignment(lessonId) {
  return {
    type: GET_ALL_LESSON_ASSIGNMENT,
    payload: lessonId
  };
}
export function GetAllLessonAssignmentSuccessfully(lessonAssignment) {
  return {
    type: GET_ALL_LESSON_ASSIGNMENT_SUCCESSFULLY,
    payload: lessonAssignment
  };
}

export function GetAllLessonAssignmentFailed(errMsg) {
  return {
    type: GET_ALL_LESSON_ASSIGNMENT_FAILED,
    errorMessage: errMsg
  };
}
