import { ofType } from 'redux-observable';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  switchMap,
  takeUntil
} from 'rxjs';
import { getLessonDone, seenLessonVideo } from 'src/apis/lesson-done.api';
import {
  createLesson,
  getLessonAll,
  getLessonById,
  getLessonBySeoTitle,
  getLessonByTag,
  getLessonHome,
  getMyLesson,
  updateLesson
} from 'src/apis/lesson.api';
import { showError, showSuccess } from 'src/utils/toast';
import { removeNullField } from '../../utils/object.utils';
import {
  CreateLessonFailed,
  CreateLessonSuccessfully,
  CREATE_LESSON,
  CREATE_LESSON_FAILED,
  GetLessonAllSuccessfully,
  GetLessonDoneSuccessfully,
  GetLessonFailed,
  GetLessonSearchSuccessfully,
  GetLessonSuccessfully,
  GetMyLessonSuccessfully,
  GET_LESSON,
  GET_LESSON_ALL,
  GET_LESSON_DONE,
  GET_LESSON_FAILED,
  GET_LESSON_HOME,
  GET_LESSON_SEARCH,
  GET_LESSON_SEO,
  GET_LESSON_BY_TAG,
  GET_MY_LESSON,
  SeenLessonSuccessfully,
  SEEN_LESSON,
  SEEN_LESSON_FAILED,
  UpdateLessonFailed,
  UpdateLessonSuccessfully,
  UPDATE_LESSON,
  UPDATE_LESSON_FAILED
} from './lesson.action';

export const createLessonEpic = (_action$) =>
  _action$.pipe(
    ofType(CREATE_LESSON),
    switchMap((action) =>
      createLesson(action.payload).pipe(
        map((res) => CreateLessonSuccessfully(res.data)),
        takeUntil(_action$.pipe(ofType(CREATE_LESSON_FAILED))),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau');
          return of(CreateLessonFailed('Failed to create lesson data'));
        })
      )
    )
  );

export const updateLessonEpic = (_action$) =>
  _action$.pipe(
    ofType(UPDATE_LESSON),
    switchMap((action) =>
      updateLesson(action.payload.id, removeNullField(action.payload.lesson)).pipe(
        map((res) => {
          showSuccess('Cập nhật thành công');
          return UpdateLessonSuccessfully(res.data);
        }),
        takeUntil(_action$.pipe(ofType(UPDATE_LESSON_FAILED))),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau');
          return of(UpdateLessonFailed('Failed to update lesson data'));
        })
      )
    )
  );

export const getLessonEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_LESSON, GET_LESSON_SEO, GET_LESSON_BY_TAG),
    switchMap((action) => {
      const getLessonApi = (() => {
        switch (action.type) {
          case GET_LESSON:
            return getLessonById;
          case GET_LESSON_SEO:
            return getLessonBySeoTitle;
          case GET_LESSON_BY_TAG:
            return getLessonByTag;
          default:
            return getLessonBySeoTitle;
        }
      })();
      return getLessonApi(action.payload).pipe(
        map((res) => GetLessonSuccessfully(res.data, action.type)),
        takeUntil(_action$.pipe(ofType(GET_LESSON_FAILED))),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau');
          return of(GetLessonFailed('Failed to get lesson data'));
        })
      );
    })
  );

export const getLessonAllEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_LESSON_ALL, GET_LESSON_SEARCH),
    debounceTime(400),
    distinctUntilChanged(),
    switchMap((action) =>
      getLessonAll({ ...action.payload }).pipe(
        map((res) =>
          action.type === GET_LESSON_SEARCH
            ? GetLessonSearchSuccessfully(res.data)
            : GetLessonAllSuccessfully(res.data)
        ),
        takeUntil(_action$.pipe(ofType(GET_LESSON_FAILED))),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau');
          return of(GetLessonFailed('Failed to get lesson data'));
        })
      )
    )
  );

export const getMyLessonEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_MY_LESSON),
    switchMap((action) =>
      getMyLesson().pipe(
        map((res) => GetMyLessonSuccessfully(res.data)),
        takeUntil(_action$.pipe(ofType(GET_LESSON_FAILED))),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau');
          return of(GetLessonFailed('Failed to get lesson data'));
        })
      )
    )
  );

export const getHomeLessonEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_LESSON_HOME),
    switchMap((action) =>
      getLessonHome().pipe(
        map((res) => GetLessonAllSuccessfully(res.data)),
        takeUntil(_action$.pipe(ofType(GET_LESSON_FAILED))),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau');
          return of(GetLessonFailed('Failed to get lesson data'));
        })
      )
    )
  );

export const seenLessonVideoEpic = (_action$) =>
  _action$.pipe(
    ofType(SEEN_LESSON),
    switchMap((action) =>
      seenLessonVideo(action.payload).pipe(
        map((res) => SeenLessonSuccessfully()),
        takeUntil(_action$.pipe(ofType(SEEN_LESSON_FAILED))),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau');
          return of(GetLessonFailed('Failed to get lesson data'));
        })
      )
    )
  );

export const getLessonDoneEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_LESSON_DONE),
    switchMap((action) =>
      getLessonDone(action.payload).pipe(
        map((res) => GetLessonDoneSuccessfully(res.data)),
        takeUntil(_action$.pipe(ofType(GET_LESSON_FAILED))),
        catchError((err) => {
          console.log('err', err);
          return of(GetLessonFailed('Failed to get lesson data'));
        })
      )
    )
  );
