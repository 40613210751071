import { Container, Typography } from '@material-ui/core';
import { RootStyle } from '../Course/CoursePreview/CoursePreview';

export function DieuKhoan() {
  return (
    <RootStyle title="The Forum Education | Điều khoản dịch vụ">
      <Container sx={{ lineHeight: 2 }}>
        <Typography variant="h3" gutterBottom textAlign="center">
          Điều khoản dịch vụ
        </Typography>
        <p>
          <strong>ĐIỀU 1. Chấp thuận các quy định – chính sách bán hàng</strong>
          <br />
          Khi sử dụng Website Theforum.vn của Chúng tôi, Quý khách đã mặc nhiên chấp thuận các quy
          định dưới đây và các chính sách bán hàng được hiển thị trên website Theforum.vn. Để biết
          được các sửa đổi mới nhất, Quý khách nên thường xuyên kiểm tra lại các chính sách trên
          website Theforum.vn.
          <br />
          Chúng tôi có quyền thay đổi, điều chỉnh, thêm hay bớt các nội dung của các chính sách hiện
          thị trên website Theforum.vn tại bất kỳ thời điểm nào. Nếu Quý khách vẫn tiếp tục sử dụng
          Website Theforum.vn sau khi có các thay đổi như vậy thì có nghĩa là Quý khách đã chấp
          thuận các thay đổi đó.
          <br />
          <strong>ĐIỀU 2. Tính chất của thông tin hiển thị</strong>
          <br />
          Các nội dung hiển thị trên Website Theforum.vn nhằm mục đích cung cấp thông tin về Chúng
          tôi, về thông tin sản phẩm và dịch vụ mà Chúng tôi đang cung cấp. Các thông tin khác liên
          quan nhằm phục vụ nhu cầu tìm hiểu của khách hàng đều được ghi rõ nguồn cung cấp.
          <br />
          <strong>ĐIỀU 3. Chính sách về bảo mật thông tin</strong>
          <br />
          Vui lòng xem chính sách của Chúng tôi về bảo mật thông tin người sử dụng website
          Theforum.vn tại Chính sách về bảo mật thông tin.
          <br />
          <strong>ĐIỀU 4. Liên kết từ Website khác</strong>
          <br />
          Chúng tôi không cho phép bất kỳ nhà cung cấp dịch vụ internet nào được phép “đặt toàn bộ”
          hay “nhúng” bất kỳ thành tố nào của Website Theforum.vn này sang một trang khác hoặc sử
          dụng các kỹ thuật làm thay đổi giao diện / hiển thị mặc định của Website Theforum.vn mà
          chưa có sự đồng ý của Chúng tôi.
          <br />
          <strong>ĐIỀU 5. Miễn trừ trách nhiệm</strong>
          <br />
          Chúng tôi từ chối trách nhiệm hay đưa ra đảm bảo rằng website Theforum.vn sẽ không có lỗi
          vận hành, an toàn, không bị gián đoạn hay tính chính xác, đầy đủ và đúng hạn của các thông
          tin hiển thị.
          <br />
          <strong>ĐIỀU 6. Quyền sở hữu trí tuệ</strong>
          <br />
          Website Theforum.vn này và mọi nội dung xếp đặt, hiển thị đều thuộc sở hữu và là tài sản
          độc quyền khai thác của Chúng tôi và các nhà cung cấp có liên quan khác. Mọi sử dụng,
          trích dẫn phải không gây thiệt hại cho Chúng tôi và đều phải tuân thủ các điều kiện sau:
          (1) Chỉ sử dụng cho mục đích cá nhân, phi thương mại; (2) các sao chép hoặc trích dẫn đều
          phải giữ nguyên dấu hiệu bản quyền hoặc các yết thị về quyền sở hữu trí tuệ như đã thể
          hiện trong phiên bản gốc; và Tất cả các nội dung được cung cấp tại Website Theforum.vn này
          không được phép nhân bản, hiển thị, công bố, phổ biến, đưa tin tức hay lưu hành cho bất cứ
          ai, dưới bất kỳ hình thức nào, kể cả trên các Website Theforum.vn độc lập khác mà không
          được sự chấp thuận của Chúng tôi.
        </p>
      </Container>
    </RootStyle>
  );
}
