import { Container, Typography } from '@material-ui/core';
import { RootStyle } from '../Course/CoursePreview/CoursePreview';

export function DatHang() {
  return (
    <RootStyle title="The Forum Education | Chính sách đặt hàng, thanh toán">
      <Container sx={{ lineHeight: 2 }}>
        Quý khách hàng lựa chọn đặt hàng qua điện thoại, qua email, facebook, website… sẽ thanh toán
        theo hình thức chuyển khoản sau:
        <Typography variant="h5" gutterBottom>
          Thanh toán chuyển khoản
        </Typography>
        Sau khi thực hiện các bước đặt hàng, nhân viên tư vấn của website Theforum.vn sẽ gọi điện
        xác nhận đơn hàng và thông báo tới Quý khách về thông tin tài khoản chuyển khoản phù hợp.
        Quý khách vui lòng chuyển khoản theo thông tin tài khoản dưới đây: <br />
        Chủ tài Khoản: Công ty TNHH The Forum Education Vietnam
        <br />
        Số Tài Khoản: 678678888 <br />
        Chủ Tài Khoản: NGUYỄN HOÀNG HUY <br />
        Ngân hàng: Ngân hàng TMCP Quốc tế Việt Nam (VIB) – Chi nhánh Vũng Tàu<br />
        <strong>Lưu ý:</strong> Sau khi chuyển khoản vui lòng thông báo với nhân viên tư vấn qua
        email: education@theforumcenter.vn hoặc gọi tới số: 1900 886640 để xác nhận về khoản tiền.
        <br />
      </Container>
    </RootStyle>
  );
}
