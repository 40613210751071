// routes
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { MHidden } from './components/@material-extend';
// components
import ScrollToTop from './components/ScrollToTop';
import withSplashScreen from './components/SplashScreen';
import { Footer } from './layouts/Footer';
import { LoginPopup } from './layouts/LoginPopup';
import { UpdatePopup } from './layouts/UpdateInfoPopup';
import Router from './routes';
// theme
import ThemeConfig from './theme';

// ----------------------------------------------------------------------

const App = () => (
  <ThemeConfig>
    <ScrollToTop />
    <LoginPopup />
    <UpdatePopup />
    <Router />
    <MHidden width="xlDown">
      <MessengerCustomerChat
        pageId="1672728689605349"
        appId="366167108187473"
        themeColor="#D14242"
      />
    </MHidden>
    <Footer />
  </ThemeConfig>
);
export default withSplashScreen(App);
