export const SIGNUP = 'SIGN_UP';
export const SIGNUP_GOOGLE = 'SIGN_UP_GOOGLE';
export const SIGNUP_GOOGLE_SUCCESFULLY = 'SIGNUP_GOOGLE_SUCCESFULLY';
export const SIGNUP_SUCCESFULLY = 'SIGNUP_SUCCESFULLY';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const TOGGLE_UPDATE_POPUP = 'TOGGLE_UPDATE_POPUP';

export function SignUp(firstName, lastName, phone, email, password) {
  return {
    type: SIGNUP,
    payload: {
      firstName,
      lastName,
      phone,
      email,
      password
    }
  };
}
export function SignUpGoogle(firstName, lastName, phone, email) {
  return {
    type: SIGNUP_GOOGLE,
    payload: {
      firstName,
      lastName,
      phone,
      email
    }
  };
}

export function SignUpSuccessful(payload) {
  return {
    type: SIGNUP_SUCCESFULLY,
    payload: {
      userInfo: payload
    }
  };
}

export function SignUpGoogleSuccessful() {
  return {
    type: SIGNUP_GOOGLE_SUCCESFULLY
  };
}

export function SignUpFailed(errMsg) {
  return {
    type: SIGNUP_FAILED,
    errorMessage: errMsg
  };
}

export function HandleUpdatePopup() {
  return {
    type: TOGGLE_UPDATE_POPUP
  };
}
