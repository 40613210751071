import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Box,
  ClickAwayListener,
  InputAdornment,
  Stack,
  TextField
} from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_REGEX } from 'src/constants/constant';
import { FindEmail } from 'src/store/payment/payment.action';
import { showError } from 'src/utils/toast';
// ----------------------------------------------------------------------

const SearchbarStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 1150,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12
      }
    },
    '& .MuiAutocomplete-inputRoot': {
      '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
      }
    },
    '& .MuiAutocomplete-option': {
      '&:not(:last-child)': {
        borderBottom: `solid 1px ${theme.palette.divider}`
      }
    }
  },

  '& .MuiAutocomplete-popper': {
    '& .MuiPaper-root': {
      overflowX: 'hidden'
    }
  }
}));

// ----------------------------------------------------------------------

export default function SearchEmail({ value, setValue }) {
  const [isOpen, setOpen] = useState(false);
  const [emails, setEmails] = useState([]);

  const paymentState = useSelector((state) => state.payment);

  const dispatch = useDispatch();

  useEffect(() => {
    onOpenHandler();
  }, [paymentState.emails]);

  const onOpenHandler = () => {
    setEmails(paymentState.emails);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeHandler = (event, values) => {
    if (!values) return;
    setValue(values);
    dispatch(FindEmail(values));
  };

  const onSearchChange = useCallback(debounce(changeHandler, 500), []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <Stack direction="row">
            <SearchbarStyle>
              <Autocomplete
                onOpen={onOpenHandler}
                size="medium"
                disableClearable
                clearOnEscape={false}
                popupIcon={null}
                noOptionsText="Đợi xíu, The Forum đang tìm..."
                options={emails}
                autoHighlight
                blurOnSelect
                unselectable="on"
                autoSelect
                autoComplete
                onInputChange={onSearchChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tìm kiếm email..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <Box
                              component={Icon}
                              icon={searchFill}
                              sx={{
                                ml: 1,
                                width: 20,
                                height: 20,
                                color: 'text.disabled'
                              }}
                            />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </SearchbarStyle>
          </Stack>
        )}
      </div>
    </ClickAwayListener>
  );
}
