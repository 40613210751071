import { Container, Grid, Skeleton, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import LeftProfile from 'src/components/profile/LeftProfile';
import RightProfile from 'src/components/profile/RightProfile';
import { GetUser } from 'src/store/user/user.action';

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  marginInline: 5,
  paddingTop: '5em',
  paddingBottom: theme.spacing(5)
}));

const Profile = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const userState = useSelector((state) => state.user.data);
  const auth = useSelector((state) => state.auth);
  const [user, setUser] = useState();

  const [activeTab, setActiveTab] = useState('tab-info');

  useEffect(() => {
    dispatch(GetUser(setUser));
  }, []);

  useEffect(() => {
    setUser(userState.current);
  }, [userState.current]);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);
  return (
    <RootStyle>
      <Container maxWidth="xl">
        {loading ? (
          <>
            <Skeleton
              width="100%"
              height="50vh"
              sx={{ top: '2vh', position: 'absolute', left: '10vw', maxWidth: '80vw' }}
            >
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton
              width="100%"
              height="20vh"
              sx={{ top: '40vh', position: 'absolute', left: '10vw', maxWidth: '80vw' }}
            >
              <Typography>.</Typography>
            </Skeleton>
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <LeftProfile user={user} picture={auth.userInfo} onChange={setActiveTab} />
            </Grid>
            <Grid item xs={12} md={9}>
              <RightProfile user={user} activeTab={activeTab} />
            </Grid>
          </Grid>
        )}
      </Container>
    </RootStyle>
  );
};
export default Profile;
