import { LESSON_PAYMENT_UNPAID } from 'src/constants/constant';
import {
  CHECKOUT_PAYMENT,
  CHECKOUT_PAYMENT_FAILED,
  CHECKOUT_PAYMENT_SUCCESSFULLY,
  CLEAR_PAYMENT,
  FIND_EMAIL_FAILED,
  FIND_EMAIL_SUCCESFULLY,
  FIND_PAYMENT,
  FIND_PAYMENT_SUCCESSFULLY,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESSFULLY,
  VERIFY_LESSON_FAILED,
  VERIFY_LESSON_SUCCESSFULLY,
  VERIFY_PAYMENT,
  VERIFY_PAYMENT_FAILED,
  VERIFY_PAYMENT_SUCCESSFULLY
} from './payment.action';

const initState = {
  data: null,
  emails: [],
  findPayment: [],
  verified: null,
  loading: false,
  lessonPaid: LESSON_PAYMENT_UNPAID,
  errorMessage: ''
};

export function paymentReducer(state = initState, action) {
  switch (action.type) {
    case VERIFY_PAYMENT:
    case CHECKOUT_PAYMENT:
      return { ...state, loading: true };
    case VERIFY_LESSON_SUCCESSFULLY:
      return { ...state, loading: false, lessonPaid: action.payload };
    case CHECKOUT_PAYMENT_SUCCESSFULLY:
      return { ...state, loading: false, data: action.payload };
    case VERIFY_LESSON_FAILED:
    case VERIFY_PAYMENT_FAILED:
    case CHECKOUT_PAYMENT_FAILED:
      return { ...state, loading: false, errorMessage: action.errorMessage };
    case VERIFY_PAYMENT_SUCCESSFULLY:
      return { ...state, loading: false, verified: true };
    case FIND_EMAIL_SUCCESFULLY:
      return { ...state, emails: action.payload };
    case FIND_EMAIL_FAILED:
      return { ...state, errorMessage: action.payload };
    case FIND_PAYMENT:
      return { ...state, loading: true, findPayment: [] };
    case FIND_PAYMENT_SUCCESSFULLY:
      return { ...state, findPayment: action.payload, loading: false };
    case UPDATE_PAYMENT:
      return { ...state, loading: true };
    case UPDATE_PAYMENT_SUCCESSFULLY:
      return { ...state, loading: false };
    case CLEAR_PAYMENT:
      return initState;
    default:
      return state;
  }
}
