import {
  CREATE_LESSON_TOPIC,
  CREATE_LESSON_TOPIC_FAILED,
  CREATE_LESSON_TOPIC_SUCCESSFULLY,
  GET_ALL_LESSON_TOPIC,
  GET_ALL_LESSON_TOPIC_FAILED,
  GET_ALL_LESSON_TOPIC_SUCCESSFULLY,
  PUBLIC_VIDEO,
  PUBLIC_VIDEO_FAILED
} from './lesson-topic.action';

const initState = {
  data: {
    selected: null,
    list: null
  },
  loading: true,
  errorMessage: ''
};

export function lessonTopicReducer(state = initState, action) {
  switch (action.type) {
    case CREATE_LESSON_TOPIC:
    case GET_ALL_LESSON_TOPIC:
    case PUBLIC_VIDEO:
      return { ...state, loading: true };
    case CREATE_LESSON_TOPIC_SUCCESSFULLY:
    case GET_ALL_LESSON_TOPIC_SUCCESSFULLY:
      return { ...state, loading: false };
    case CREATE_LESSON_TOPIC_FAILED:
    case GET_ALL_LESSON_TOPIC_FAILED:
    case PUBLIC_VIDEO_FAILED:
      return { ...state, loading: false, errorMessage: action.errorMessage };
    default:
      return state;
  }
}
