import { ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs';
import { fetchAllPokemon } from 'src/apis/example.api';
import { LoadExampleDataSuccessful, LOAD_DATA, LOAD_DATA_FAILED } from './example.action';

export const loadExampleDataEpic = (_action$, _state$) =>
  _action$.pipe(
    ofType(LOAD_DATA),
    switchMap(() =>
      fetchAllPokemon().pipe(
        map((res) => LoadExampleDataSuccessful(res.data.results)),
        takeUntil(_action$.pipe(ofType(LOAD_DATA_FAILED))),
        catchError((err) => {
          console.log('err', err);
          throw new Error('Failed to load example data');
        })
      )
    )
  );
