import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { AddBoxOutlined } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MotionContainer } from 'src/components/animate';
import { transitionValues } from 'src/components/animate/MotionContainer';
import { DISCOUNT } from 'src/constants/constant';
import { ThumbnailStyled } from 'src/layouts/Searchbar';
import { GetCoupon } from 'src/store/coupon/coupon.action';
import { SetContentDialogSupport, ToggleDialogSupport } from 'src/store/dialogs/dialog.action';
import { formatCurrency } from 'src/utils/formatcurrency';
import { removeAscent } from 'src/utils/removeascent';

const SummaryStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  flex: '0 1 40%'
}));

const SummaryListStyle = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    justifyContent: 'space-between'
  },
  '& .text-align-right': {
    textAlign: 'right'
  }
}));

const SummaryInfo = ({
  isAuthorized,
  lesson,
  coupon,
  currentUser,
  values,
  openLoginPopup,
  isSubmitting
}) => {
  const dispatch = useDispatch();

  const [currentCoupon, setCurrentCoupon] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState('');
  const [lastPrice, setLastPrice] = useState(lesson?.price);
  const [error, setError] = useState('');

  const handleDiscount = () => {
    const code = currentCoupon;
    dispatch(GetCoupon(code));
    // dispatch(VerifyCoupon({ code: code, lessonId: lesson._id }));
  };

  useEffect(() => {
    if (coupon.selected) {
      setAppliedCoupon(`${coupon.selected.code} -${coupon.selected.percent}%`);
      setLastPrice(
        lesson?.price *
          (1 - DISCOUNT / 100) *
          (1 - (coupon.selected ? coupon.selected.percent / 100 : 0))
      );
    }
    if (coupon.errorMessage) {
      setError(coupon.errorMessage);
    } else {
      setError('');
    }
  }, [coupon]);

  return (
    <SummaryStyle>
      <Stack sx={{ mb: 1 }}>
        <Typography variant="h4" gutterBottom>
          Thông tin đơn hàng
        </Typography>
      </Stack>

      <Stack justifyContent="center">
        <ThumbnailStyled
          sx={{ objectFit: 'scale-down', alignSelf: 'center', width: '50%' }}
          alt={lesson?.name}
          src={lesson?.thumbnail}
        />
        <Typography variant="h5" gutterBottom sx={{ alignSelf: 'center' }}>
          {lesson?.name}
        </Typography>
      </Stack>
      <Stack>
        <Box marginBottom={3}>
          <SummaryListStyle>
            {isAuthorized && (
              <>
                <ListItem>
                  <ListItemText primary="Tên khách hàng" sx={{ minWidth: 'fit-content' }} />
                  <ListItemText
                    className="text-align-right"
                    primary={removeAscent(`${currentUser?.firstName} ${currentUser?.lastName}`)}
                    sx={{ wordBreak: 'break-word', marginInline: '10px' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Email" sx={{ minWidth: 'fit-content' }} />
                  <ListItemText
                    className="text-align-right"
                    primary={currentUser?.email}
                    sx={{ wordBreak: 'break-word', marginInline: '10px' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Số điện thoại" sx={{ minWidth: 'fit-content' }} />
                  <ListItemText
                    className="text-align-right"
                    primary={currentUser?.phone}
                    sx={{ wordBreak: 'break-word', marginInline: '10px' }}
                  />
                </ListItem>
              </>
            )}
            <ListItem>
              <ListItemText primary="Giá gốc" sx={{ minWidth: 'fit-content' }} />
              <ListItemText
                sx={{ wordBreak: 'break-word', marginInline: '10px' }}
                className="text-align-right"
                primary={formatCurrency(lesson?.price)}
              />
            </ListItem>
            {values.address && (
              <ListItem>
                <ListItemText primary="Địa chỉ" sx={{ minWidth: 'fit-content' }} />
                <ListItemText
                  className="text-align-right"
                  primary={values.address}
                  sx={{ wordBreak: 'break-word', marginInline: '10px' }}
                />
              </ListItem>
            )}
            {values.city && (
              <ListItem>
                <ListItemText primary="Thành phố" sx={{ minWidth: 'fit-content' }} />
                <ListItemText
                  className="text-align-right"
                  primary={values.city}
                  sx={{ wordBreak: 'break-word', marginInline: '10px' }}
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText primary="Phương thức thanh toán" sx={{ minWidth: 'fit-content' }} />
              <ListItemText
                className="text-align-right"
                primary={values.paymentMethod}
                sx={{ wordBreak: 'break-word', marginInline: '10px' }}
              />
            </ListItem>
            {DISCOUNT > 0 && (
              <ListItem>
                <ListItemText primary="Sale off Đặc Biệt" sx={{ minWidth: 'fit-content' }} />
                <ListItemText
                  className="text-align-right"
                  primary={`đã giảm ${DISCOUNT}%`}
                  sx={{ wordBreak: 'break-word', marginInline: '10px' }}
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText primary="Mã giảm giá" sx={{ minWidth: 'fit-content' }} />
              <ListItemText
                className="text-align-right"
                primary={appliedCoupon ? <i>{appliedCoupon} </i> : 'Chưa áp dụng'}
                sx={{ wordBreak: 'break-word', marginInline: '10px' }}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Tổng giá trị" sx={{ minWidth: 'fit-content' }} />
              <ListItemText
                className="text-align-right"
                primary={<strong>{formatCurrency(lastPrice)}</strong>}
                sx={{ color: 'red', fontWeight: 'bolder', wordBreak: 'break-word' }}
              />
            </ListItem>
            <Typography variant="body2" align="left" sx={{ color: 'text.secondary', mt: 2 }}>
              Thông tin hóa đơn sẽ được gửi qua số điện thoại và email, hãy kiểm tra lại thông tin
              của mình nhé.
            </Typography>
            {lastPrice === 0 && (
              <Alert severity="success" sx={{ my: 1 }}>
                <AlertTitle>Nhận khoá học ngay</AlertTitle>
                Khoá học của bạn là miễn phí, tiếp tục Thanh toán để nhận khoá học!
              </Alert>
            )}
            {(error || appliedCoupon) && (
              <Alert severity={error ? 'error' : 'success'} sx={{ my: 1 }}>
                {error || `Áp dụng mã giảm giá ${appliedCoupon} thành công!`}
              </Alert>
            )}
          </SummaryListStyle>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              type="text"
              aria-label="discount"
              fullWidth
              size="small"
              label="Mã giảm giá"
              variant="outlined"
              id="discount"
              placeholder="Nhập mã giảm giá"
              onChange={(e) => setCurrentCoupon(e.currentTarget.value)}
              value={currentCoupon}
            />
            <IconButton onClick={handleDiscount}>
              <AddBoxOutlined fontSize="large" />
            </IconButton>
          </Stack>
        </Box>
        <LoadingButton
          fullWidth
          type="submit"
          size="large"
          variant="contained"
          loading={isSubmitting || openLoginPopup}
          sx={{ width: '50%', alignSelf: 'end' }}
        >
          Thanh toán
        </LoadingButton>

        <MotionContainer initial="initial" open>
          <motion.div
            variants={{
              animate: {
                scale: ['0.98', '0.98', '1.05'],
                color: ['#ff0000', '#ff0000', '#FFC107'],
                transition: transitionValues
              }
            }}
            style={{
              textAlign: 'right',
              marginBottom: '10px',
              originX: 0.75
            }}
          >
            <Button
              fullWidth
              variant="contained"
              color="warning"
              sx={{ mt: 1, mb: 3, width: '50%', alignSelf: 'end' }}
              onClick={() => {
                dispatch(
                  SetContentDialogSupport({
                    ...(currentUser
                      ? {
                          name: `${currentUser.firstName} ${currentUser.lastName}`,
                          phone: currentUser.phone
                        }
                      : { name: '', phone: '' }),
                    note: `Tôi muốn tư vấn thêm về khóa học ${lesson.name} `
                  })
                );
                dispatch(ToggleDialogSupport());
              }}
            >
              TƯ VẤN THÊM
            </Button>
          </motion.div>
        </MotionContainer>
      </Stack>
    </SummaryStyle>
  );
};

export default SummaryInfo;
