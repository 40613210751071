import { Container, InputLabel, Stack, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setDocuemt } from 'src/apis/firebase.api';
import { showSuccess } from 'src/utils/toast';
import * as Yup from 'yup';

const CustomLabel = styled(InputLabel)(({ theme }) => ({
  color: 'inherit'
}));

const baseUrl =
  'https://script.google.com/macros/s/AKfycbwfHFz2ZXNgd2-wnpLimove1YaZvZxxKUPU4aY7VVYD8ABKsaGb_BuipXbzyEnLqLY/exec';

const SupportSectionForm = ({ setSent, sent, sx }) => {
  const supportDialog = useSelector((state) => state.dialogs.supportDialog);

  const SupportSchema = Yup.object().shape({
    name: Yup.string().required('Vui lòng điền tên của bạn'),
    phone: Yup.string().required('Vui lòng điền số điện thoại'),
    course: Yup.string(),
    note: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: supportDialog.content.name || '',
      phone: supportDialog.content.phone || '',
      note: supportDialog.content.note || 'Tôi muốn tìm hiểu khóa học ... '
    },
    validationSchema: SupportSchema,
    onSubmit: async () => {
      // DO AUTH
      // const ipRes = await axios.get('https://geolocation-db.com/json/');
      const dataToSend = {
        ...values,
        agent: window.navigator.userAgent,
        ip: 'Viet Nam',
        date: new Date().toLocaleString('vi-VN')
      };
      await Promise.all([
        axios.post(baseUrl, dataToSend, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }),
        setDocuemt('tuvankhoahoc', new Date().toLocaleString().replaceAll('/', '-'), dataToSend)
      ]);
      setSent(true);
      showSuccess(
        `Cảm ơn bạn ${supportDialog.content.name} đã tin tưởng chúng tôi, chúng tôi sẽ liên hệ bạn ngay!`
      );
    }
  });

  useEffect(() => {
    if (supportDialog.content) setFieldValue(supportDialog.content);
  }, [supportDialog]);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={sx}>
        <Typography variant="h3" pb={5} textAlign="center" sx={{ position: 'relative' }}>
          Đăng ký liên hệ tư vấn
        </Typography>
        <Stack spacing={3}>
          <CustomLabel>Tên của bạn *</CustomLabel>
          <TextField
            fullWidth
            autoComplete="name"
            type="text"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white'
              }
            }}
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <CustomLabel>Số điện thoại của bạn *</CustomLabel>
          <TextField
            fullWidth
            autoComplete="phone"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white'
              }
            }}
            type="text"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
          <CustomLabel>Ghi chú thêm</CustomLabel>
          <TextField
            fullWidth
            multiline
            minRows={4}
            autoComplete="note"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white'
              }
            }}
            type="text"
            {...getFieldProps('note')}
            error={Boolean(touched.note && errors.note)}
            helperText={touched.note && errors.note}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting && !sent}
          sx={{ my: 2, color: 'white' }}
        >
          Đăng ký ngay
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};

export default SupportSectionForm;
